import React from 'react';
import styled from 'styled-components';
import { isNotStandaloneMobilePWA, isStandalonePWA, pwaStyleAdapter } from '../../../utils/mobileSupported';
import { isMobile, isTablet } from 'react-device-detect';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';

const BattlesReward = ({ battlesAssets }) => {
  return (
    <InfoContainer source={battlesAssets.BATTLES_INFO_BOX}>
      <InfoText
        dangerouslySetInnerHTML={{
          __html: getText(TEXT_KEY.BATTLES_FINISH_STREAK),
        }}
      />
      <InfoAmountContainer>
        <CoinIcon src={assetSource(ASSET_KEY.COIN_ICON)} alt="Coin Icon" />
        <Amount>1234567890</Amount>
      </InfoAmountContainer>
    </InfoContainer>
  );
};

export default BattlesReward;

const isMobileBrowser = isMobile && isNotStandaloneMobilePWA && !isTablet;
const isMobilePwa = isStandalonePWA && !isTablet;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: ${isMobileBrowser ? 65 : 75}%;
  justify-content: center;
  align-items: center;
  background-image: url(${({ source }) => source});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 1;
  position: relative;
  padding: ${isMobilePwa ? '20vh 0 0 0' : isTablet ? '15vh 0 0 0' : isMobileBrowser ? '17vh 0 0 0' : '16vh 0 0'};
  ${isMobile && !isTablet && 'margin-bottom: 5vh;'}
`;

const InfoText = styled.span`
  color: #ffffff;
  font-size: ${pwaStyleAdapter(3.5, 'vh', 1.2)};
  text-align: center;
  line-height: 1.2;
  font-weight: 600;
  text-transform: uppercase;
`;

const InfoAmountContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const CoinIcon = styled.img`
  position: relative;
  width: 3vh;
  height: 3vh;
  margin: 0 0.25vh;
  top: 3px;
`;

const Amount = styled.span`
  color: #ffffff;
  font-size: ${pwaStyleAdapter(3.5, 'vh', 1.2)};
  text-align: center;
  line-height: 1.2;
  font-weight: 800;
  text-transform: uppercase;
`;

