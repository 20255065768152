import _defineProperty from "/var/jenkins/workspace/Consolidated/Code/Dev/Web@2/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
import { battlesMockDataAssets } from '../../Battles/mockdata';
import { MISSIONS_TYPES, MISSIONS_STATES } from '../constants/missionsInfo';
var generateTabs = function generateTabs(_ref) {
  var _ref$missionStatus = _ref.missionStatus,
    missionStatus = _ref$missionStatus === void 0 ? null : _ref$missionStatus;
  return [[{
    TYPE: MISSIONS_TYPES.EXPLORER,
    TAB: battlesMockDataAssets.BATTLES_TABS_EXPLORER_REGULAR
  }, {
    TYPE: MISSIONS_TYPES.BATTLES,
    TAB: missionStatus && missionStatus === MISSIONS_STATES.RUNNING ? battlesMockDataAssets.BATTLES_TABS_DISABLED : battlesMockDataAssets.BATTLES_TABS_DOWN
  }], [{
    TYPE: MISSIONS_TYPES.EXPLORER,
    TAB: battlesMockDataAssets.BATTLES_TABS_EXPLORER_DOWN
  }, {
    TYPE: MISSIONS_TYPES.BATTLES,
    TAB: battlesMockDataAssets.BATTLES_TABS_REGULAR
  }]];
};
var generateTutorialInfoScreen = function generateTutorialInfoScreen(missionsAssets) {
  return [missionsAssets.TUTORIAL_INFO_SCREEN_STEP_1, missionsAssets.TUTORIAL_INFO_SCREEN_STEP_2, missionsAssets.TUTORIAL_INFO_SCREEN_STEP_3];
};
var generateLayoutProgressbar = function generateLayoutProgressbar(missionsAssets) {
  return _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty({}, MISSIONS_STATES.COMPLETED, {
    LayoutBar: missionsAssets === null || missionsAssets === void 0 ? void 0 : missionsAssets.BAR_BASE_PURPLE,
    ShadowBar: missionsAssets === null || missionsAssets === void 0 ? void 0 : missionsAssets.BAR_SHADOW
  }), MISSIONS_STATES.FINISHED, {
    LayoutBar: missionsAssets === null || missionsAssets === void 0 ? void 0 : missionsAssets.BAR_BASE_PURPLE,
    ShadowBar: missionsAssets === null || missionsAssets === void 0 ? void 0 : missionsAssets.BAR_SHADOW
  }), MISSIONS_STATES.REWARD, {
    LayoutBar: missionsAssets === null || missionsAssets === void 0 ? void 0 : missionsAssets.BAR_COLLECT_BUTTON,
    ShadowBar: missionsAssets === null || missionsAssets === void 0 ? void 0 : missionsAssets.BAR_SHADOW
  }), MISSIONS_STATES.LOCKED, {
    LayoutBar: missionsAssets === null || missionsAssets === void 0 ? void 0 : missionsAssets.BAR_BASE_PURPLE,
    ShadowBar: missionsAssets === null || missionsAssets === void 0 ? void 0 : missionsAssets.BAR_SHADOW,
    IconLocked: missionsAssets === null || missionsAssets === void 0 ? void 0 : missionsAssets.BAR_LOCKED_ICON
  }), MISSIONS_STATES.READY, {
    IconReady: missionsAssets === null || missionsAssets === void 0 ? void 0 : missionsAssets.BAR_PLAY_ICON
  }), MISSIONS_STATES.RUNNING, {
    LayoutBar: missionsAssets === null || missionsAssets === void 0 ? void 0 : missionsAssets.BAR_BASE_BLUE,
    LayoutBarPurple: missionsAssets === null || missionsAssets === void 0 ? void 0 : missionsAssets.BAR_BASE_PURPLE,
    ShadowBar: missionsAssets === null || missionsAssets === void 0 ? void 0 : missionsAssets.BAR_SHADOW,
    FillBarStart: missionsAssets === null || missionsAssets === void 0 ? void 0 : missionsAssets.BAR_FILL_SIDE,
    FillBarProgess: missionsAssets === null || missionsAssets === void 0 ? void 0 : missionsAssets.BAR_FILL,
    FillBarEnd: missionsAssets === null || missionsAssets === void 0 ? void 0 : missionsAssets.BAR_FILL_SIDE
  });
};
export { generateTabs, generateTutorialInfoScreen, generateLayoutProgressbar };