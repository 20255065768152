import React from 'react';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import { Spinner } from 'react-bootstrap';
import TermsModal from '../../components/TermsModal';
import { landingPageTemplates } from './landingPageTemplates';
import { TrackingURLContainer } from './styledComponents';
import audio from 'shared/utils/audio';
import { availableLoginMethodsCount } from 'shared/screens/Start/utils/loginMethodsCounter';
import RegularTemplate from './components/RegularTemplate';
import SignupTemplate from './components/SignupTemplate';

const TrackingURL = props => {
  const {
    landingPageState: {
      template,
      backgroundImage,
      authLoading,
      statusMessage,
      socialError,
      socialErrorType,
      error,
      isTermsModalVisible,
      terms,
      termsAccepted,
      selectedTerms,
      firstCheckBoxClicked,
      secondCheckBoxClicked,
    },
    landingPageState,
    updateLandingPageState,
    termsAndConditionsUrl,
    privacyPolicyUrl,
    navigation,
    onFacebookActivate,
    onGuestActivate,
    redirectToSplash,
    aff_id,
    closeModal,
    onSubmit,
    closeTermsModal,
    checkSelectedTerms,
  } = props;

  const isSignUpEnabled = template === landingPageTemplates.signupEnabled;

  const setTerms = () => {
    audio.onClick();
    updateLandingPageState({ termsAccepted: !termsAccepted, terms: !terms });
  };

  const setFirstCheckBoxClicked = () => {
    audio.onClick();
    updateLandingPageState({ firstCheckBoxClicked: !firstCheckBoxClicked });
  };

  const setSecondCheckBoxClicked = () => {
    audio.onClick();
    updateLandingPageState({ secondCheckBoxClicked: !secondCheckBoxClicked });
  };

  const { isFacebookAvailable } = availableLoginMethodsCount();

  return (
    <>
      <TrackingURLContainer backgroundImage={`url(${backgroundImage})`}>
        {isSignUpEnabled ? (
          <SignupTemplate
            landingPageState={landingPageState}
            setFirstCheckBoxClicked={setFirstCheckBoxClicked}
            setSecondCheckBoxClicked={setSecondCheckBoxClicked}
            navigation={navigation}
            termsAndConditionsUrl={termsAndConditionsUrl}
            privacyPolicyUrl={privacyPolicyUrl}
            isFacebookAvailable={isFacebookAvailable}
            onGuestActivate={onGuestActivate}
            onFacebookActivate={onFacebookActivate}
            aff_id={aff_id}
            redirectToSplash={redirectToSplash}
          />
        ) : (
          <RegularTemplate landingPageState={landingPageState} redirectToSplash={redirectToSplash} aff_id={aff_id} />
        )}
      </TrackingURLContainer>
      <TermsModal
        isVisible={isTermsModalVisible}
        closeModal={closeModal}
        closeRetryModal={closeModal}
        terms={terms}
        setTerms={setTerms}
        onSubmit={onSubmit}
        onFacebookActivate={onFacebookActivate}
        closeTermsModal={closeTermsModal}
        selectedTerms={selectedTerms}
        checkSelectedTerms={checkSelectedTerms}
        error={error}
        socialError={socialError}
        socialErrorType={socialErrorType}
        statusMessage={statusMessage}
        clientText={getText(TEXT_KEY.CLIENT_TITLE)}
        termsAndConditionsUrl={termsAndConditionsUrl}
        privacyPolicyUrl={privacyPolicyUrl}
      />
      {authLoading && <Spinner style={{ position: 'absolute' }} animation="grow" className="custom-spinner" />}
    </>
  );
};

export default TrackingURL;

