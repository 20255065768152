import { isMobile, isTablet } from 'react-device-detect';
import { isNotStandaloneMobilePWA, isStandalonePWA } from '../../../utils/mobileSupported';

const windowWidth = window.innerWidth;
const windowHeight = window.innerHeight;

export const getContentModalWrapperDimension = cssProp => {
  const baseWidth = isTablet
    ? '115%'
    : isMobile
      ? '90%'
      : windowWidth < 1400
        ? windowHeight > 800
          ? `${windowWidth * 0.95}px`
          : `${windowWidth * 0.85}px`
        : `${windowWidth * 0.8}px`;
  const baseHeight = isStandalonePWA
    ? 100
    : isNotStandaloneMobilePWA
      ? 115
      : windowWidth < 1400
        ? windowHeight > 800
          ? 92
          : 105
        : 100;
  const baseLeft = isTablet
    ? '-12%'
    : isMobile
      ? '0%'
      : windowWidth < 1400
        ? windowHeight > 800
          ? '-190px'
          : 'auto'
        : '-14%';

  const dimensions = {
    width: baseWidth,
    height: baseHeight,
    left: baseLeft,
  };

  return dimensions[cssProp];
};

export const getCloseButtonTop = () => {
  if (isNotStandaloneMobilePWA && !isTablet) return '15dvh';
  if (isStandalonePWA) return '14vh';
  if (isTablet) return '15vh';
  if (windowWidth < 1400) {
    return windowHeight > 800 ? '13vh' : '15vh';
  }
  return windowHeight < 880 ? '14vh' : '15vh';
};

export const getRightCloseButton = () => {
  if (windowWidth > 1800) return '-2%';
  if (isNotStandaloneMobilePWA && !isTablet) return 'calc(-3% + -4px)';

  return '-3%';
};
