import _defineProperty from "/var/jenkins/workspace/Consolidated/Code/Dev/Web@2/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
import Utils from './';
var memoizedHandlers = {};
export var memoize = function memoize(_ref) {
  var id = _ref.id,
    type = _ref.type,
    handler = _ref.handler;
  if (memoizedHandlers[type] && !(id in memoizedHandlers[type])) {
    memoizedHandlers[type][id] = handler;
  } else if (!(type in memoizedHandlers)) {
    memoizedHandlers[type] = _defineProperty({}, id, handler);
  }
  return memoizedHandlers[type][id];
};
export var ltoCallBackHandler = function ltoCallBackHandler(store, setLTOOffer) {
  return function () {
    var isLToPurchase = function isLToPurchase() {
      return store.getState().prePurchase.ltoPurchase;
    };
    if (!isLToPurchase() || Utils.isWebOrMobileWeb()) {
      store.dispatch(setLTOOffer(null));
    }
  };
};