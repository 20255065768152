import React from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import styled from 'styled-components';
import { isNotStandaloneMobilePWA, isStandalonePWA } from '../../../utils/mobileSupported';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';
import Tab from '../../../components/Tab/Tab';
import BattlesTabsHeader from './BattlesTabsHeader';
import BattlesTabsScreen from './BattlesTabsScreen';

/**
 * @param {{ battlesFeatureState: import('shared/screens/Battles/mockdata').BattlesFeatureState, battlesAssets: any }} props .
 * @returns {JSX.Element}
 */

const BattlesGoal = props => {
  const { battlesFeatureState, battlesAssets } = props;

  return (
    <Container>
      <BattlesGoalContainer source={battlesAssets.BATTLES_BATTLE_GOAL_BOX}>
        <InfoText>{battlesFeatureState.battlesFeature.battlesFeatureChallenge.goalText}</InfoText>
        <InfoAmountContainer>
          <CoinIcon src={assetSource(ASSET_KEY.COIN_ICON)} alt="Coin Icon" />
          <Amount>{battlesFeatureState.battlesFeature.battlesFeatureChallenge.goalAmout}</Amount>
        </InfoAmountContainer>
      </BattlesGoalContainer>
      <BattlesTabsContainer>
        <Tab tabContainerTheme={{ height: '100%', width: '100%', paddingTop: 0, margin: 0 }}>
          <Tab.Navigator headerComponent={BattlesTabsHeader}>
            <Tab.Screen
              component={
                <BattlesTabsScreen
                  battlesAssets={battlesAssets}
                  rank={battlesFeatureState.battlesFeature.battlesFeatureChallenge.progress.current.rank}
                  progress={battlesFeatureState.battlesFeature.battlesFeatureChallenge.progress.current.progress}
                  points={battlesFeatureState.battlesFeature.battlesFeatureChallenge.progress.current.points}
                />
              }
              battlesAssets={battlesAssets}
              screenContainerTheme={screenContainerTheme}
            />
            <Tab.Screen
              component={
                <BattlesTabsScreen
                  battlesAssets={battlesAssets}
                  rank={battlesFeatureState.battlesFeature.battlesFeatureChallenge.progress.streak.rank}
                  progress={battlesFeatureState.battlesFeature.battlesFeatureChallenge.progress.streak.progress}
                  points={battlesFeatureState.battlesFeature.battlesFeatureChallenge.progress.streak.points}
                />
              }
              battlesAssets={battlesAssets}
              screenContainerTheme={screenContainerTheme}
            />
          </Tab.Navigator>
        </Tab>
      </BattlesTabsContainer>
    </Container>
  );
};

export default BattlesGoal;

const isMobileBrowser = isMobile && isNotStandaloneMobilePWA && !isTablet;
const isMobilePwa = isStandalonePWA && !isTablet;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  padding: ${isMobilePwa ? '3vh 5vw 20vh 4vw' : isMobileBrowser ? '9vh 0 2vh 0' : '10vh 0 5vh 0'};
`;

const BattlesGoalContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: ${isMobileBrowser ? '23vw' : '100%'};
  height: 100%;
  justify-content: center;
  align-items: center;
  background-image: url(${({ source }) => source});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
`;

const BattlesTabsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: ${isMobileBrowser ? '23vw' : '100%'};
  height: 100%;
  justify-content: center;
  align-items: center;
  ${isMobileBrowser && 'margin-bottom: 5vh;'}
`;

const InfoText = styled.span`
  color: #ffffff;
  font-size: 3.5vh;
  text-align: center;
  line-height: 1.2;
  font-weight: 600;
  text-transform: uppercase;
  margin-top: 3vh;
`;

const InfoAmountContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const CoinIcon = styled.img`
  position: relative;
  width: 3vh;
  height: 3vh;
  margin: 0 0.25vh;
  top: 3px;
`;

const Amount = styled.span`
  color: #ffffff;
  font-size: 3.5vh;
  text-align: center;
  line-height: 1.2;
  font-weight: 800;
  text-transform: uppercase;
`;

const screenContainerTheme = {
  bottom: (isTablet && isStandalonePWA) || isStandalonePWA ? '19vh' : isMobileBrowser ? '15vh' : '16vh',
  width: isMobileBrowser ? '21vw' : '30vw',
  height: isMobileBrowser ? '14vh' : '16vh',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0',
};

