import React, { useState, useMemo } from 'react';
import { useReactTable, getCoreRowModel, flexRender } from '@tanstack/react-table';
import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';
import { useBattlesPlayers } from 'shared/screens/Battles/mockdata';
import { isMobile, isTablet } from 'react-device-detect';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import { isNotStandaloneMobilePWA, isStandalonePWA } from '../../../utils/mobileSupported';

/**
 * @param {{ battlesFeatureState: import('shared/screens/Battles/mockdata').BattlesFeatureState, battlesAssets: any, isPlayerJoining: boolean, battlesStarted: boolean }} props .
 * @returns {JSX.Element}
 */

const BattlesRankingTable = ({ battlesFeatureState, battlesAssets, isPlayerJoining, battlesStarted }) => {
  const [players, setPlayers] = useState(battlesFeatureState.players);

  useBattlesPlayers({
    players,
    setPlayers,
    battlesStarted,
    isPlayerJoining,
    countdown: battlesFeatureState.battlesFeature.battlesFeatureCountdown,
  });

  const columns = useMemo(
    () => [
      {
        header: getText(TEXT_KEY.BATTLES_RANK),
        cell: ({ row }) =>
          isPlayerJoining && !row.original.hasJoined ? getText(TEXT_KEY.BATTLES_DASH) : row.index + 1,
      },
      {
        header: getText(TEXT_KEY.BATTLES_PLAYER),
        accessorKey: 'name',

        cell: ({ row }) => {
          return isPlayerJoining && !row.original.hasJoined ? (
            <p>
              {getText(TEXT_KEY.BATTLES_JOINING)
                .split('')
                .map((char, index) => (
                  <span key={index}>
                    {char === '.' ? <AnimatedLetter delay={index * 0.1}>{char}</AnimatedLetter> : char}
                  </span>
                ))}
            </p>
          ) : (
            <PlayerText id={row.original.id}>{row.original.name + ' ' + row.original.lastName}</PlayerText>
          );
        },
      },
      {
        header: getText(TEXT_KEY.BATTLES_POINTS),
        accessorKey: 'points',
        cell: ({ row, getValue }) => (
          <Points index={row.index}>
            {row.index < 3 && <PointsIcon src={battlesAssets.BATTLES_POINTS_ICON_BATTLES_SMALL} alt="points-icon" />}
            {isPlayerJoining && !row.original.hasJoined ? getText(TEXT_KEY.BATTLES_DASH) : getValue()}
          </Points>
        ),
      },
      {
        header: getText(TEXT_KEY.BATTLES_PROGRESS),
        accessorKey: 'progress',
        cell: ({ row, getValue }) =>
          isPlayerJoining && !row.original.hasJoined ? getText(TEXT_KEY.BATTLES_DASH) : getValue(),
      },
    ],
    [isPlayerJoining, battlesAssets]
  );

  const table = useReactTable({
    data: players,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <Container>
      <Table>
        <thead>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => (
                <TableHeaderCell key={header.id}>
                  {flexRender(header.column.columnDef.header, header.getContext())}
                </TableHeaderCell>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          <AnimatePresence>
            {table.getRowModel().rows.map(row => (
              <>
                {row.original.id === 0 && (
                  <div
                    style={{
                      backgroundColor: 'rgb(255, 192, 0)',
                      width: isMobilePwa ? '38vw' : isMobileBrowser ? '32vw' : '50vw',
                      height: '6vh',
                      position: 'absolute',
                      borderRadius: '50px',
                      marginLeft: isMobileBrowser ? '0vw' : '.5vw',
                      zIndex: -1,
                    }}
                  />
                )}
                <motion.tr
                  key={row.original.id}
                  layout
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: 10 }}
                  style={{ color: row.original.id === 0 ? 'black' : 'white' }}
                >
                  {row.getVisibleCells().map(cell => (
                    <TableDataCell key={cell.id}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableDataCell>
                  ))}
                </motion.tr>
              </>
            ))}
          </AnimatePresence>
        </tbody>
      </Table>
    </Container>
  );
};

export default BattlesRankingTable;

const isMobileBrowser = isMobile && !isTablet && isNotStandaloneMobilePWA;
const isMobilePwa = isStandalonePWA && !isTablet;

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  flex-wrap: wrap;
`;

const Table = styled.table`
  width: 100%;
  height: 100%;
  border-collapse: collapse;
`;

const TableHeaderCell = styled.th`
  text-align: center;
  font-weight: 800;
  text-transform: uppercase;
  font-size: ${isMobileBrowser || isMobilePwa ? 2 : 2.5}rem;
`;

const TableDataCell = styled.td`
  text-align: center;
  font-weight: 600;
  font-size: ${isMobileBrowser || isMobilePwa ? 1.5 : 2}rem;
`;

const Points = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5vw;
  justify-content: flex-start;
  padding-left: ${({ index }) => (index < 3 ? 2 : 5.5)}vw;
`;

const PointsIcon = styled.img`
  object-fit: contain;
  width: ${isMobileBrowser ? 2.5 : 3}vw;
`;

const AnimatedLetter = styled.span`
  opacity: 0;
  animation: blink 2s infinite;
  animation-delay: ${({ delay }) => delay}s;
  display: inline-block;

  @keyframes blink {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;

const PlayerText = styled.p`
  text-align: initial;
  position: relative;
  left: ${isMobile ? 1 : 2.5}vw;
  margin: 0;
  padding: 0;
`;

