import VipAccessFlow from './VipAccessFlow';
import { connect } from '../../node_modules/react-redux';
var mapStateToProps = function mapStateToProps(state) {
  return {
    gameInfo: state.gameInfo,
    coin: state.coin.value,
    isVipRoomUnlocked: state.consumables.isVipRoomUnlocked,
    enterRequirements: state.properties.data.enterRequirements
  };
};
export default connect(mapStateToProps, null)(VipAccessFlow);