import _slicedToArray from "/var/jenkins/workspace/Consolidated/Code/Dev/Web@2/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import { useState, useLayoutEffect, useEffect, useRef } from 'react';
import useNavState from '../../../hooks/useNavState';
import useFeatureFlags from '../../../hooks/useFeatureFlags';
import QueryParamProxy from '../../../utils/queryParamProxy';
import timestampManager, { normalizeTimestamp } from '../../../utils/TimestampManager';
import screens from '../..';
import { battlesMockDataAssets, battlesMockFeatureState } from '../mockdata';
import { FE_BATTLES } from '../../../utils/featureFlags/constants';
var useBattles = function useBattles(_ref) {
  var navigation = _ref.navigation;
  var _useFeatureFlags = useFeatureFlags(),
    hasFeature = _useFeatureFlags.hasFeature;
  useLayoutEffect(function () {
    if (!hasFeature(FE_BATTLES)) QueryParamProxy.navigate(navigation, screens.Home);
  }, [hasFeature, navigation]);
  useNavState();
  var timer = useRef(null);
  var _useState = useState(false),
    _useState2 = _slicedToArray(_useState, 2),
    battlesStarted = _useState2[0],
    setBattlesStarted = _useState2[1];
  var _useState3 = useState(true),
    _useState4 = _slicedToArray(_useState3, 2),
    isPlayerJoining = _useState4[0],
    setIsPlayerJoining = _useState4[1];
  var battlesTimestamp = timer.current && timestampManager.getTimestampRegistered(timer.current) + 1000;
  var handleBattlesStarted = function handleBattlesStarted() {
    setBattlesStarted(true);
    var countdown = normalizeTimestamp(battlesMockFeatureState.battlesFeatureState.battlesFeature.battlesFeatureCountdown);
    if (timer.current) {
      timestampManager.unregister(timer.current);
      timer.current = null;
    }
    timer.current = timestampManager.register(countdown, function () {
      return setIsPlayerJoining(false);
    });
  };
  useEffect(function () {
    return function () {
      if (timer.current) {
        timestampManager.unregister(timer.current);
        timer.current = null;
      }
    };
  }, []);
  return {
    battlesFeatureState: battlesMockFeatureState.battlesFeatureState,
    battlesAssets: battlesMockDataAssets,
    battlesStarted: battlesStarted,
    setBattlesStarted: setBattlesStarted,
    isPlayerJoining: isPlayerJoining,
    handleBattlesStarted: handleBattlesStarted,
    battlesTimestamp: battlesTimestamp
  };
};
export default useBattles;