import React, { Component } from 'react';
import SOutOfCoin from 'shared/screens/OutOfCoin';
import './OutOfCoin.scss';
import responsiveFontSize from '../../lib/responsiveFontSize';
import theme from 'shared/assets/style/theme';
import ENVIRONMENT_VARIABLES from 'shared/utils/environmentVariables';
import ThemeContext from 'shared/context/ThemeContext';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';
import generateRender from 'shared/utils/generateRender';
import Button from '../../components/Button/Button';
import { BUTTON_STROKES } from 'shared/utils/buttonTextStrokes';
import { isNotStandaloneMobilePWA } from '../../utils/mobileSupported';

export default class OutOfCoin extends Component {
  static get AsModalProps() {
    return {
      dialogClassName: isNotStandaloneMobilePWA ? 'gtp-modal-out-of-coin-small' : 'gtp-modal-out-of-coin',
    };
  }

  render() {
    const themeContext = this.context;
    const visitStoreButtonSrc = generateRender({
      ti: assetSource(ASSET_KEY.ROUNDED_RECT_PRIMARY),
      default: assetSource(ASSET_KEY.ROUNDED_RECT_TERTIARY),
    });

    return (
      <SOutOfCoin
        navigation={this.props.navigation}
        {...this.props}
        render={args => {
          const { quickBuyOfferDetails, quickBuyHandler, visitStoreHandler } = args;
          const coinAmount = quickBuyOfferDetails.coinAmount ? quickBuyOfferDetails.coinAmount : 0;
          const loyaltyAmount = quickBuyOfferDetails.loyaltyAmount ? quickBuyOfferDetails.loyaltyAmount : 0;
          return (
            <div className="outOfCoinContainer">
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <div
                  style={{
                    flex: 1,
                  }}
                >
                  <img
                    src={assetSource(ASSET_KEY.OUT_OF_COINS_STATIC_IMAGE)}
                    alt="Out Of Coins Background"
                    style={{ height: '100%', width: '100%' }}
                  />
                  <div style={themeContext.OutOfCoin.QuickBuyOfferDetails}>
                    <div
                      className="quickBuyCoinContainer"
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        width: '100%',
                      }}
                    >
                      <img src={assetSource(ASSET_KEY.COIN_ICON)} className="OOCIcon" alt="Coin Icon" />
                      <span
                        className={`OOCText OOCGradientText ${ENVIRONMENT_VARIABLES.APPLICATION_TARGET}`}
                        data-label={coinAmount}
                      >
                        {coinAmount}
                      </span>
                    </div>
                    <div
                      className="quickBuyLoyaltyContainer"
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        width: '100%',
                      }}
                    >
                      <span
                        className="quickBuyPlusIcon"
                        style={{
                          alignSelf: 'center',
                          fontSize: responsiveFontSize(14, 26),
                        }}
                      >
                        {quickBuyOfferDetails.plusIcon}
                      </span>
                      <img
                        src={assetSource(ASSET_KEY.LOYALTY_ICON)}
                        className="loyaltyIcon"
                        alt="Loyalty Icon"
                        style={{
                          width: '6%',
                          alignSelf: 'center',
                        }}
                      />
                      <span
                        className={`OOCGradientText ${ENVIRONMENT_VARIABLES.APPLICATION_TARGET}`}
                        style={{
                          fontWeight: 600,
                          textShadow: `2px 2px 4px ${theme.palette.secondary[0]}`,
                          marginLeft: 0,
                          alignSelf: 'center',
                          display: 'inline-block',
                          fontSize: responsiveFontSize(14, 26),
                        }}
                        data-label={loyaltyAmount}
                      >
                        {loyaltyAmount}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div style={themeContext.OutOfCoin.OutOfCoinButtons}>
                <Button
                  textStroke={BUTTON_STROKES.SECONDARY}
                  imageSource={assetSource(ASSET_KEY.ROUNDED_RECT_SECONDARY)}
                  onClick={quickBuyHandler}
                  label={quickBuyOfferDetails.totalAmount}
                  containerStyle={{
                    margin: '1rem',
                  }}
                />
                <Button
                  textStroke={BUTTON_STROKES.TERTIARY}
                  imageSource={visitStoreButtonSrc}
                  label={getText(TEXT_KEY.VISIT_STORE)}
                  onClick={visitStoreHandler}
                  textStyle={themeContext.OutOfCoin.VisitStoreText}
                />
              </div>
            </div>
          );
        }}
      />
    );
  }
}

OutOfCoin.contextType = ThemeContext;
OutOfCoin.hideOverlay = true;

