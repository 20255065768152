import React, { Component } from 'react';
import theme from 'shared/assets/style/theme';
import ThemeContext from 'shared/context/ThemeContext';
import SLinkAccountForm from 'shared/screens/LinkAccountForm';
import { checkIfOtpRequeridedConsents } from 'shared/screens/OneTimePassword/utils';
import ENVIRONMENT_VARIABLES from 'shared/utils/environmentVariables';
import FormButton from './components/FormButton';
import FormFooter from './components/FormFooter';
import FormInputs from './components/FormInputs';
import FormSubtitle from './components/FormSubtitle';
import { styles } from './styles';
import { TEXT_KEY, getText } from 'shared/utils/localization';
import { onPressHelp } from 'shared/utils/redirectionHelp';
import { FormHeader } from './components/FormHeader';
import LinkText from '../../components/LinkText/LinkText';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';

export default class LinkAccountForm extends Component {
  state = {
    formValues: {
      accountNumber: '',
      year: '',
      month: '',
      day: '',
      propertyId: '1',
      pin: '',
    },
    activeOTPCheckbox: false,
  };

  clearError = null;

  static get AsModalProps() {
    return {
      dialogClassName: 'gtp-modal-link-account',
      hideBeforeSelector: ENVIRONMENT_VARIABLES.APPLICATION_TARGET === 'hri',
    };
  }

  handleLinkBtn = handleLinkCallback => {
    handleLinkCallback(this.state.formValues);
  };

  handleInputChange = key => event => {
    this.clearError();
    this.setState(prevState => ({
      formValues: {
        ...prevState.formValues,
        [key]: event.value !== null ? event.value.toString() : '',
      },
    }));
  };

  handleOPTCheckbox = () => {
    this.setState(prevState => ({
      activeOTPCheckbox: !prevState.activeOTPCheckbox,
    }));
  };

  render() {
    const themeContext = this.context;
    const {
      formValues: { propertyId },
    } = this.state;

    return (
      <SLinkAccountForm
        {...this.props}
        render={args => {
          const {
            handleLinkAccount,
            handlePinAndAccount,
            handlePinPropertyAccount,
            handleRegisterRewardCard,
            success,
            message,
            error,
            termsAndConditionsUrl,
            propertySelector,
            userProperty,
            setPropertyId,
            clearError,
            enableOrderCard,
            findSelectedPropertyObject,
          } = args;

          let propertySelected = setPropertyId(userProperty, propertyId);
          const selectedPropertyObj = propertySelected !== '' && findSelectedPropertyObject(propertySelected);
          this.clearError = clearError;

          return (
            <div style={themeContext.LinkAccountForm.ScreenBackground}>
              <div style={styles.header}>
                <FormHeader
                  source={assetSource(ASSET_KEY.FULL_MODAL_HEADER)}
                  ibw={require(asset`full-modal-header.ibw`)}
                  style={themeContext.LinkAccountForm.HeaderImage}
                  dividerStyle={themeContext.LinkAccountForm.Divider}
                >
                  <div style={themeContext.LinkAccountForm.HeaderTitleContainer}>
                    <span style={themeContext.LinkAccountForm.HeaderTitle}>{getText(TEXT_KEY.LINK_CARD_TITLE)}</span>
                    <LinkText
                      customStyle={styles.linkText}
                      onClick={onPressHelp}
                      underline={false}
                      theme={themeContext.LinkAccountForm.NeedHelpText}
                    >
                      {getText(TEXT_KEY.NEED_HELP)}
                    </LinkText>
                  </div>
                </FormHeader>
              </div>
              <div className="hidenScrollBar" style={themeContext.LinkAccountForm.FormContainer}>
                <FormSubtitle />
                {success && (
                  <p
                    style={{
                      textAlign: 'center',
                      color: theme.palette.success[1],
                    }}
                  >
                    {message}
                  </p>
                )}
                {!success && (
                  <>
                    <FormInputs handleInputChange={this.handleInputChange} formValues={this.state.formValues} />
                    {!success && error && (
                      <p
                        style={{
                          textAlign: 'center',
                          color: theme.palette.error[1],
                          paddingTop: 2,
                        }}
                      >
                        {error}
                      </p>
                    )}
                    <FormFooter
                      termsAndConditionsUrl={termsAndConditionsUrl}
                      enableOrderCard={selectedPropertyObj ? selectedPropertyObj?.enableOrderCard : enableOrderCard}
                      handleRegisterRewardCard={handleRegisterRewardCard}
                      showOTPConsents={
                        selectedPropertyObj?.requiresConsents ||
                        checkIfOtpRequeridedConsents(setPropertyId(userProperty, propertyId), propertySelector)
                      }
                      activeOTPCheckbox={this.state.activeOTPCheckbox}
                      handleOPTCheckbox={this.handleOPTCheckbox}
                      selectedPropertyObj={selectedPropertyObj}
                    />
                    <FormButton
                      handleLinkBtn={this.handleLinkBtn}
                      handleLinkAccount={handleLinkAccount}
                      handlePinAndAccount={handlePinAndAccount}
                      handlePinPropertyAccount={handlePinPropertyAccount}
                      error={error}
                      activeOTPCheckbox={this.state.activeOTPCheckbox}
                      showOTPConsents={
                        selectedPropertyObj?.requiresConsents ||
                        checkIfOtpRequeridedConsents(setPropertyId(userProperty, propertyId), propertySelector)
                      }
                    />
                  </>
                )}
              </div>
            </div>
          );
        }}
      />
    );
  }
}

LinkAccountForm.contextType = ThemeContext;

