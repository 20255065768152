import _defineProperty from "/var/jenkins/workspace/Consolidated/Code/Dev/Web@2/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import useNotification from '../../../hooks/useNotification';
import PromotionAction, { PromoActionKey } from '../../../utils/PromotionAction';
import { getText, TEXT_KEY } from '../../../utils/localization';
import { useSelector } from 'react-redux';
import navigation from '../../../utils/navigation/navigation';
import CdnPath from '../../../utils/cdnPath';
import audio from '../../../utils/audio';
import { promoSlidePressEventHandler } from '../utils';
import { GAME_TRIGGERS } from '../../../constants/types';
export default function usePromoDataHandler(_ref) {
  var itemData = _ref.itemData,
    slideIndex = _ref.slideIndex;
  var notificationContext = useNotification();
  var addNotification = notificationContext.addNotification;
  var _useSelector = useSelector(function (state) {
      return state.app.assetsUrls;
    }),
    ImagesCdnBaseUrl = _useSelector.ImagesCdnBaseUrl;
  var isAccountLinked = useSelector(function (state) {
    return state.playerInfo.isAccountLinked === 'yes';
  });
  var itemsHandler = [];
  var promoActionHandler = function promoActionHandler(bannerData, bannerIndex) {
    var action = bannerData.action,
      game = bannerData.game,
      externalUrl = bannerData.externalUrl,
      eventId = bannerData.eventId,
      campaign_id = bannerData.campaign_id;
    var eventHandlerPayload = {
      slideNumber: slideIndex + 1,
      bannerNumber: bannerIndex + 1,
      action: _objectSpread(_objectSpread(_objectSpread({
        type: action
      }, game && {
        game: game
      }), externalUrl && {
        externalUrl: externalUrl
      }), eventId && {
        eventId: eventId
      }),
      campaign_id: campaign_id
    };
    var handler = null;
    if (action === PromoActionKey.LINK_LOYALTY_CARD && isAccountLinked) {
      handler = function handler() {
        audio.onClick();
        promoSlidePressEventHandler(eventHandlerPayload);
        addNotification({
          message: getText(TEXT_KEY.ACCOUNT_ALREADY_LINKED),
          title: getText(TEXT_KEY.ACCOUNT_ALREADY_LINKED_TITLE)
        });
      };
    } else {
      handler = function handler() {
        audio.onClick();
        promoSlidePressEventHandler(eventHandlerPayload);
        return PromotionAction.getActionCallback(navigation, {
          type: action,
          game: game,
          externalUrl: externalUrl,
          eventId: eventId,
          trigger: GAME_TRIGGERS.PROMO_BANNER
        }, notificationContext)();
      };
    }
    return handler;
  };
  var getImageUrl = function getImageUrl(image) {
    return "".concat(ImagesCdnBaseUrl).concat(CdnPath.LOBBY_ADVERTS).concat(image);
  };
  itemData === null || itemData === void 0 || itemData.forEach(function (bannerData, index) {
    var actionHandler = promoActionHandler(bannerData, index);
    var imageUrl = getImageUrl(bannerData.image);
    itemsHandler.push({
      actionHandler: actionHandler,
      imageUrl: imageUrl
    });
  });
  return itemsHandler;
}