import _defineProperty from "/var/jenkins/workspace/Consolidated/Code/Dev/Web@2/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _slicedToArray from "/var/jenkins/workspace/Consolidated/Code/Dev/Web@2/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import { useState } from 'react';
import { generateTabs } from '../utils/generateMissionRendering';
import { MISSIONS_STATES, MISSIONS_TYPES } from '../constants';
import QueryParamProxy from '../../../utils/queryParamProxy';
import screens from '../../../screens';
var useMissionsTabs = function useMissionsTabs(props) {
  var navigation = props.navigation,
    index = props.index,
    currentMission = props.currentMission;
  var _useState = useState(generateTabs({
      missionStatus: currentMission === null || currentMission === void 0 ? void 0 : currentMission.missionStatus
    })[index]),
    _useState2 = _slicedToArray(_useState, 2),
    selectedTab = _useState2[0],
    setSelectedTab = _useState2[1];
  var handleOnPress = function handleOnPress(index, missionType) {
    var missionStatus = currentMission === null || currentMission === void 0 ? void 0 : currentMission.missionStatus;
    if (missionStatus !== MISSIONS_STATES.RUNNING) {
      setSelectedTab(generateTabs({
        missionStatus: missionStatus
      })[index]);
      QueryParamProxy.navigate(navigation, _defineProperty(_defineProperty({}, MISSIONS_TYPES.EXPLORER, screens.Missions), MISSIONS_TYPES.BATTLES, screens.Battles)[missionType]);
    }
  };
  return {
    selectedTab: selectedTab,
    handleOnPress: handleOnPress
  };
};
export default useMissionsTabs;