import React from 'react';
import ENVIRONMENT_VARIABLES from 'shared/utils/environmentVariables';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import Signup from '../../../Signup';
import {
  ContentContainer,
  ClientLogo,
  PageDescriptionContainer,
  MainImage,
  PageDescription,
  LinkIconHolder,
  InnerDiv,
  Badge,
  PageBottomText,
  MainContainer,
  FormContainer,
  FormTitle,
  SignInButtonWrapper,
  SignInButtonImage,
  PlayAsGuestButtonWrapper,
} from '../../styledComponents';
import Button from '../../../../components/Button/Button';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';
import { pwaStyleAdapter } from '../../../../utils/mobileSupported';
import CustomScrollBar from '../../../../components/CustomScrollBar';

function DefaultSignupTemplate({
  landingPageState,
  setFirstCheckBoxClicked,
  setSecondCheckBoxClicked,
  termsAndConditionsUrl,
  privacyPolicyUrl,
  navigation,
  onGuestActivate,
  onFacebookActivate,
  aff_id,
  redirectToSplash,
  isFacebookAvailable,
}) {
  const { mainImage, firstCheckBoxClicked, secondCheckBoxClicked, trackingPageDescription, trackingPageBottomTxt } =
    landingPageState;

  return (
    <>
      <ClientLogo src={assetSource(ASSET_KEY.TRACKING_URL_LOGO)} alt="" isSignUpEnabled />
      <PageDescriptionContainer isSignUpEnabled>
        <PageDescription>{trackingPageDescription}</PageDescription>
      </PageDescriptionContainer>
      <CustomScrollBar>
        <MainContainer>
          <FormContainer>
            <FormTitle>{getText(TEXT_KEY.SIGNUP_TITLE)}</FormTitle>
            <Signup
              isModal={false}
              isLandingPage={true}
              zIndex={101}
              firstCheckBoxClicked={firstCheckBoxClicked}
              secondCheckBoxClicked={secondCheckBoxClicked}
              setFirstCheckBoxClicked={setFirstCheckBoxClicked}
              setSecondCheckBoxClicked={setSecondCheckBoxClicked}
              enableRegisterButton={firstCheckBoxClicked}
              termsAndConditionsUrl={termsAndConditionsUrl}
              privacyPolicyUrl={privacyPolicyUrl}
              navigation={navigation}
            />
            {ENVIRONMENT_VARIABLES.PLAY_AS_GUEST === 'true' && (
              <PlayAsGuestButtonWrapper>
                <Button
                  imageStyle={{ width: pwaStyleAdapter(15, 'em') }}
                  label={getText(TEXT_KEY.PLAY_AS_GUEST)}
                  imageSource={assetSource(ASSET_KEY.GREEN_BUTTON_LANDING)}
                  textStyle={{ fontSize: '21px', fontWeight: '800' }}
                  onClick={onGuestActivate}
                  textStroke={'black'}
                />
              </PlayAsGuestButtonWrapper>
            )}
            {isFacebookAvailable && (
              <SignInButtonWrapper className="splash-facebook-button" onClick={onFacebookActivate}>
                <SignInButtonImage src={assetSource(ASSET_KEY.FACEBOOK_BUTTON)} />
              </SignInButtonWrapper>
            )}
          </FormContainer>
          <ContentContainer isSignUpEnabled>
            <MainImage src={mainImage} alt="" onError="this.onerror=null" />
            <LinkIconHolder>
              {ENVIRONMENT_VARIABLES.APPLE_STORE_URL && (
                <InnerDiv>
                  <a href={ENVIRONMENT_VARIABLES.APPLE_STORE_URL}>
                    <Badge src={assetSource(ASSET_KEY.BADGE_I_OS)} alt="" />
                  </a>
                </InnerDiv>
              )}
              <InnerDiv>
                <a href={ENVIRONMENT_VARIABLES.ANDROID_STORE_URL}>
                  <Badge src={assetSource(ASSET_KEY.BADGE_ANDROID)} alt="" />
                </a>
              </InnerDiv>
              <InnerDiv>
                <a href="#" onClick={() => redirectToSplash(aff_id.current)}>
                  <Badge src={assetSource(ASSET_KEY.BADGE_WEB)} alt="" />
                </a>
              </InnerDiv>
            </LinkIconHolder>
            <PageBottomText>{trackingPageBottomTxt}</PageBottomText>
          </ContentContainer>
        </MainContainer>
      </CustomScrollBar>
    </>
  );
}

export default DefaultSignupTemplate;

