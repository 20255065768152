import React from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import { battlesMockDataAssets } from 'shared/screens/Battles/mockdata';
import { MISSIONS_TYPES } from 'shared/screens/Missions/constants';
import useMissionsTabs from 'shared/screens/Missions/hooks/useMissionsTabs';
import { tutorialOpacity } from 'shared/utils/tutorial';
import styled from 'styled-components';
import TimeRemainingText from '../../../components/TimeRemainingText';

/**
 *
 * @param {{
 * tutorialStep: number, activeTutorial: boolean,
 * navigation: import('react-router-dom').useNavigate,
 * index: number, battlesTimestamp: number,
 * isPlayerJoining: boolean,
 * currentMission: import('shared/state/slices/missions/missions').MissionsState['currentMission']}} props
 * @returns {JSX.Element}
 */

const MissionTabs = props => {
  const { tutorialStep, activeTutorial, battlesTimestamp, isPlayerJoining } = props;
  const { selectedTab, handleOnPress } = useMissionsTabs(props);

  return (
    <TabContainer tutorialStep={tutorialStep} activeTutorial={activeTutorial}>
      {selectedTab.map(({ TYPE, TAB }, index) => (
        <>
          <Tab key={`tab-${index}`} src={TAB} onClick={() => handleOnPress(index, TYPE)} />
          {TYPE === MISSIONS_TYPES.BATTLES && isPlayerJoining && (
            <TimerContainer source={battlesMockDataAssets.BATTLES_TOP_BAR_BATTLES_TIMER_BACKING}>
              <TimeRemainingText
                style={{
                  marginTop: '2.8vh',
                  marginRight: isMobile ? '4vh' : '5vh',
                  fontSize: isMobile ? '1.5rem' : '2rem',
                }}
                until={battlesTimestamp}
                hideOnFinish
              />
            </TimerContainer>
          )}
        </>
      ))}
    </TabContainer>
  );
};

export default MissionTabs;

const TabContainer = styled.div`
  opacity: ${({ tutorialStep, activeTutorial }) => tutorialOpacity(activeTutorial && tutorialStep > 1)};
  position: absolute;
  display: flex;
  flex-direction: row;
  top: 10vh;
  right: 3vw;
  gap: 1vw;
  z-index: 10;

  ${isMobile &&
  !isTablet &&
  `
    top: 15vh;
    right: 7vw;
  `}
`;

const Tab = styled.img`
  width: ${isMobile && !isTablet ? 11 : 14}vw;
  object-fit: contain;
  cursor: pointer;
  z-index: 2;
`;

const TimerContainer = styled.div`
  z-index: 1;
  position: absolute;
  display: flex;
  background: url(${({ source }) => source}) no-repeat;
  background-size: contain;
  background-position: center;
  width: ${isMobile && !isTablet ? 11 : 14}vw;
  height: 11vh;
  right: 0;
  justify-content: flex-end;
  align-items: center;
`;

