import generateRender from 'shared/utils/generateRender';
import DefaultSignupTemplate from './DefaultSignupTemplate';
import JamulSignupTemplate from './JamulSignupTemplate';

const SignupTemplate = generateRender({
  default: DefaultSignupTemplate,
  jamul: JamulSignupTemplate,
});

export default SignupTemplate;

