import React from 'react';
import ENVIRONMENT_VARIABLES from 'shared/utils/environmentVariables';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import Signup from '../../../Signup';
import {
  ContentContainerWithOffset,
  ClientLogo,
  PageDescriptionContainer,
  MainImage,
  PageDescription,
  InnerDiv,
  Badge,
  PageBottomText,
  MainContainer,
  LeftSideLinkIconHolder,
  FormTitle,
  SignInButtonWrapper,
  SignInButtonImage,
  PlayAsGuestButtonWrapper,
  LeftSideContainer,
  LeftSideFormContainer,
} from '../../styledComponents';
import Button from '../../../../components/Button/Button';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';
import { pwaStyleAdapter } from '../../../../utils/mobileSupported';
import CustomScrollBar from '../../../../components/CustomScrollBar';

function JamulSignupTemplate({
  landingPageState,
  setFirstCheckBoxClicked,
  setSecondCheckBoxClicked,
  termsAndConditionsUrl,
  privacyPolicyUrl,
  navigation,
  onGuestActivate,
  onFacebookActivate,
  aff_id,
  redirectToSplash,
  isFacebookAvailable,
}) {
  const { mainImage, firstCheckBoxClicked, secondCheckBoxClicked, trackingPageDescription, trackingPageBottomTxt } =
    landingPageState;

  return (
    <>
      <ClientLogo src={assetSource(ASSET_KEY.TRACKING_URL_LOGO)} alt="" isSignUpEnabled />
      <PageDescriptionContainer isSignUpEnabled>
        <PageDescription>{trackingPageDescription}</PageDescription>
      </PageDescriptionContainer>
      <CustomScrollBar containerStyle={Styles.scrollBarContainerStyle}>
        <MainContainer>
          <LeftSideContainer>
            <LeftSideFormContainer>
              <FormTitle>{getText(TEXT_KEY.SIGNUP_TITLE)}</FormTitle>
              <Signup
                isModal={false}
                isLandingPage={true}
                zIndex={101}
                firstCheckBoxClicked={firstCheckBoxClicked}
                secondCheckBoxClicked={secondCheckBoxClicked}
                setFirstCheckBoxClicked={setFirstCheckBoxClicked}
                setSecondCheckBoxClicked={setSecondCheckBoxClicked}
                enableRegisterButton={firstCheckBoxClicked}
                termsAndConditionsUrl={termsAndConditionsUrl}
                privacyPolicyUrl={privacyPolicyUrl}
                navigation={navigation}
              />
              {ENVIRONMENT_VARIABLES.PLAY_AS_GUEST === 'true' && (
                <PlayAsGuestButtonWrapper>
                  <Button
                    imageStyle={{ width: pwaStyleAdapter(15, 'em') }}
                    label={getText(TEXT_KEY.PLAY_AS_GUEST)}
                    imageSource={assetSource(ASSET_KEY.GREEN_BUTTON_LANDING)}
                    textStyle={{ fontSize: '21px', fontWeight: '800' }}
                    onClick={onGuestActivate}
                    textStroke={'black'}
                  />
                </PlayAsGuestButtonWrapper>
              )}
              {isFacebookAvailable && (
                <SignInButtonWrapper className="splash-facebook-button" onClick={onFacebookActivate}>
                  <SignInButtonImage src={assetSource(ASSET_KEY.FACEBOOK_BUTTON)} />
                </SignInButtonWrapper>
              )}
            </LeftSideFormContainer>
            <LeftSideLinkIconHolder>
              <InnerDiv>
                <a href="#" onClick={() => redirectToSplash(aff_id.current)}>
                  <Badge src={assetSource(ASSET_KEY.BADGE_WEB_APP)} alt="" />
                </a>
              </InnerDiv>
              <InnerDiv>
                <a href={ENVIRONMENT_VARIABLES.ANDROID_STORE_URL}>
                  <Badge src={assetSource(ASSET_KEY.BADGE_ANDROID)} alt="" />
                </a>
              </InnerDiv>
              <InnerDiv>
                <a href="#" onClick={() => redirectToSplash(aff_id.current)}>
                  <Badge src={assetSource(ASSET_KEY.BADGE_WEB)} alt="" />
                </a>
              </InnerDiv>
            </LeftSideLinkIconHolder>
          </LeftSideContainer>
          <ContentContainerWithOffset isSignUpEnabled>
            <MainImage src={mainImage} alt="" onError="this.onerror=null" />
            <PageBottomText>{trackingPageBottomTxt}</PageBottomText>
          </ContentContainerWithOffset>
        </MainContainer>
      </CustomScrollBar>
    </>
  );
}

export default JamulSignupTemplate;

const Styles = {
  scrollBarContainerStyle: {
    height: 'unset',
  },
};

