import React from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import styled from 'styled-components';
import { isNotStandaloneMobilePWA, isStandalonePWA } from '../../../utils/mobileSupported';
import Button from '../../../components/Button/Button';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import BattlesReward from './BattlesReward';
import BattlesRankingTable from './BattlesRankingTable';
import BattlesGoal from './BattlesGoal';

const BattlesRoom = props => {
  const { battlesAssets, battlesStarted, isPlayerJoining } = props;

  return (
    <Container source={battlesAssets.BATTLES_BACKGROUND_MAIN} battlesStarted={battlesStarted}>
      <LeaderboardContainer source={battlesAssets.BATTLES_LEADERBOARD}>
        <BattlesRankingTable {...props} />
      </LeaderboardContainer>
      <RankContainer>
        {isPlayerJoining ? <BattlesReward {...props} /> : <BattlesGoal {...props} />}

        <BattlesBeginButton
          label={getText(TEXT_KEY.LET_THE_BATTLES_BEGIN)}
          imageSource={
            isPlayerJoining
              ? assetSource(ASSET_KEY.ROUNDEDRECT_DISABLED_WIDE)
              : assetSource(ASSET_KEY.ROUNDEDRECT_PRIMARY_WIDE)
          }
          onClick={() => {}}
        />
      </RankContainer>
    </Container>
  );
};

export default BattlesRoom;

const isMobileBrowser = isMobile && isNotStandaloneMobilePWA && !isTablet;
const isMobilePwa = isStandalonePWA && !isTablet;

const Container = styled.div`
  display: ${({ battlesStarted }) => (battlesStarted ? 'flex' : 'none')};
  flex-direction: row;
  width: 100vw;
  height: 100%;
  justify-content: center;
  align-items: flex-end;
  background-image: url(${({ source }) => source});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 1;
  position: relative;
  overflow: hidden;
`;

const LeaderboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: ${isMobilePwa ? 55 : isMobileBrowser ? 60 : 65}vw;
  height: ${isMobileBrowser ? 94 : 100}%;
  justify-content: center;
  align-items: center;
  background-image: url(${({ source }) => source});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 1;
  position: relative;
  padding: ${isMobilePwa
    ? ' 26vh 8vw 5vh 8vw'
    : isTablet && isStandalonePWA
      ? '28vh 7vw 15vh 7vw'
      : isMobileBrowser
        ? '21vh 14vw 5vh 14vw'
        : '28vh 7vw 5vh 7vw'};
`;

const RankContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: ${isMobilePwa ? 45 : isMobileBrowser ? 40 : 35}vw;
  height: 100%;
  justify-content: flex-end;
  align-items: center;
  z-index: 1;
  padding: ${isMobilePwa ? '8vh 1.5vw 5vh 1.5vw' : '5vh 1.5vw'};
`;

const BattlesBeginButton = styled(Button).attrs({
  containerStyle: `
    width: ${isMobileBrowser ? 22 : 30}vw;
    `,
  imageStyle: `
    width: ${isMobileBrowser ? 22 : 30}vw;
    `,
  textStyle: `
    font-size: ${isMobileBrowser ? 1.5 : 2}vw;
    text-transform: uppercase;
    font-weight : 600;
    `,
})``;

