import theme from 'shared/assets/style/theme';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';
import defaultTheme from './defaultTheme';
import { isIOS, isMobile, isTablet } from 'react-device-detect';
import { pwaStyleAdapter, isNotStandaloneMobilePWA, isStandalonePWA } from '../utils/mobileSupported';

const windowWidth = window.innerWidth;
const windowHeight = window.innerHeight;

const JamulTheme = {
  ...defaultTheme,
  LimitedTimeOffer: {
    ...defaultTheme.LimitedTimeOffer,
    BannerContainer: {
      ...defaultTheme.LimitedTimeOffer.BannerContainer,
      marginBottom: '1.5%',
    },
    StarIcon: {
      ...defaultTheme.LimitedTimeOffer.StarIcon,
      width: '16%',
    },
    BuyButton: {
      ...defaultTheme.LimitedTimeOffer.BuyButton,
      left: 'calc(50% - 85px)',
    },
    MainContainer: {
      ...defaultTheme.LimitedTimeOffer.MainContainer,
      marginBottom: '0.2%',
      height: '23%',
    },
  },
  DailySpin: {
    ...defaultTheme.DailySpin,
    MileStoneRowLoyaltyIcon: {
      ...defaultTheme.DailySpin.MileStoneRowLoyaltyIcon,
      height: 28,
      width: 28,
    },
    DaysStreakContainerSmallScreen: {
      ...defaultTheme.DailySpin.DaysStreakContainerSmallScreen,
      padding: '0rem 1rem 0.5rem 1rem',
      margin: isTablet && windowWidth > 1150 ? '0 1rem 0 1rem' : '0 1rem 0.5rem 1rem',
      maxHeight:
        windowHeight > 350 ? 350 : windowHeight > 330 ? 330 : windowHeight > 310 ? 310 : windowHeight > 300 ? 280 : 260,
    },
    RegDaysItemLoyaltyIcon: {
      ...defaultTheme.DailySpin.RegDaysItemLoyaltyIcon,
      height: 20,
      width: 20,
    },
    WheelItems: {
      ...defaultTheme.DailySpin.WheelItems,
      textStroke: '2px #003c5e',
      WebkitTextStroke: '2px #003c5e',
    },
  },
  LobbyOverlay: {
    ...defaultTheme.LobbyOverlay,
    PinnedPanelContainer: {
      ...defaultTheme.LobbyOverlay.PinnedPanelContainer,
      paddingBottom: isMobile ? '4rem' : '1rem',
    },
  },
  PurchaseFlow: {
    ...defaultTheme.PurchaseFlow,
    BillingProfileDownArrowIcon: {
      ...defaultTheme.PurchaseFlow.BillingProfileDownArrowIcon,
      marginBottom: !isTablet && isMobile ? '0.3em' : 0,
    },
    CreditCardDetailDownArrowIcon: {
      ...defaultTheme.PurchaseFlow.CreditCardDetailDownArrowIcon,
      marginBottom: !isTablet && isMobile ? '0.3em' : 0,
    },
  },
  MissionCard: {
    ...defaultTheme.MissionCard,
    DescriptionText: {
      ...defaultTheme.MissionCard.DescriptionText,
      fontSize: isNotStandaloneMobilePWA && !isTablet ? '1.9vh' : '2.3vh',
    },
    TimerTextWithIcon: {
      ...defaultTheme.MissionCard.TimerTextWithIcon,
      fontSize: '2.65vh',
    },
    TimerTextRegular: {
      ...defaultTheme.MissionCard.TimerTextRegular,
      fontSize: isTablet ? '2.15vh' : '2.35vh',
      fontWeight: '700',
    },
    CoinImage: {
      ...defaultTheme.MissionCard.CoinImage,
      marginTop: isNotStandaloneMobilePWA ? 7 : 0,
    },
  },
  NavigationBar: {
    ...defaultTheme.NavigationBar,
    BarSection: {
      ...defaultTheme.NavigationBar.BarSection,
      paddingBottom: 15,
    },
    LoyaltyCurrencyWrapperStyle: {
      ...defaultTheme.NavigationBar.LoyaltyCurrencyWrapperStyle,
      position: 'absolute',
      right: windowWidth < 1300 ? 'calc(50% - 200px)' : 'calc(50% - 205px)',
    },
    TopBar: {
      ...defaultTheme.NavigationBar.TopBar,
      height: '5.7rem',
    },
    BuyDealText: {
      ...defaultTheme.NavigationBar.BuyDealText,
      left: '0.2rem',
      top: '-0.15rem',
    },
    FullButton: {
      ...defaultTheme.NavigationBar.FullButton,
      left: 0,
    },
  },
  Bar: {
    ...defaultTheme.Bar,
    BarContainer: {
      ...defaultTheme.Bar.BarContainer,
      background: '#00373e',
    },
    BarProgress: {
      ...defaultTheme.Bar.BarProgress,
      background: 'linear-gradient(to bottom, #ff4814 0%, #ff8500 50%, #ffa400 100%)',
    },
    BarText: {
      ...defaultTheme.Bar.BarText,
      marginLeft: 'auto',
    },
  },
  Tier: {
    ...defaultTheme.Tier,
    Card: {
      ...defaultTheme.Tier.Card,
      width: 75,
      ...(!isIOS && { top: -1 }),
    },
  },
  VipAccessFlow: {
    ...defaultTheme.VipAccessFlow,
    VipAccessButtonText: {
      ...defaultTheme.VipAccessFlow.VipAccessButtonText,
      fontWeight: 800,
    },
    VipAccessButtonImage: {
      ...defaultTheme.VipAccessFlow.VipAccessButtonText,
      width: 210,
    },
    VipAccessRedeemButtonText: {
      ...defaultTheme.VipAccessFlow.VipAccessRedeemButtonText,
      fontWeight: 800,
      color: theme.palette.common[42],
    },
  },
  OutOfCoin: {
    ...defaultTheme.OutOfCoin,
    QuickBuyOfferDetails: {
      ...defaultTheme.OutOfCoin.QuickBuyOfferDetails,
      bottom: windowHeight > 1200 ? '12.5%' : '15.5%',
    },
  },
  DefaultTooltip: {
    ...defaultTheme.DefaultTooltip,
    Border: {
      ...defaultTheme.DefaultTooltip.Border,
      stopColor1: '#f5e9c0',
      stopColor2: '#d2ab2d',
    },
    Background: {
      ...defaultTheme.DefaultTooltip.Background,
      stopColor1: '#007079',
      stopColor2: '#00232C',
    },
  },
  SmartBanner: {
    ...defaultTheme.SmartBanner,
    LogoButtonStyle: {
      ...defaultTheme.SmartBanner.LogoButtonStyle,
      height: '150%',
      bottom: '30%',
      left: '-30%',
    },
  },
  BonusLinkMessage: {
    ...defaultTheme.BonusLinkMessage,
    BoxContainer: {
      ...defaultTheme.BonusLinkMessage.BoxContainer,
      top: '-10vh',
    },
    PromoImage: {
      ...defaultTheme.BonusLinkMessage.PromoImage,
      paddingTop: '376px',
    },
    BonusLinkButton: {
      ...defaultTheme.BonusLinkMessage.BonusLinkButton,
      textShadow: `1px 1px 0px ${theme.palette.secondary[0]}, 1px -1px 0px ${theme.palette.secondary[0]}, -1px 1px 0px ${theme.palette.secondary[0]}, -1px -1px 0px ${theme.palette.secondary[0]}`,
    },
    CoinsBonusContainer: {
      ...defaultTheme.BonusLinkMessage.CoinsBonusContainer,
      bottom: '-3.7rem',
    },
    LPBonusContainer: {
      ...defaultTheme.BonusLinkMessage.LPBonusContainer,
      bottom: '-2.5rem',
    },
  },
  OfferDetail: {
    ...defaultTheme.OfferDetail,
    PurchaseResponseHighlightHeader: {
      ...defaultTheme.OfferDetail.PurchaseResponseHighlightHeader,
      fontWeight: '600',
    },
    PurchaseResponseHeader: {
      ...defaultTheme.OfferDetail.PurchaseResponseHeader,
      fontWeight: '600',
    },
    RewardCenterPurchaseResponseMessage: {
      ...defaultTheme.OfferDetail.RewardCenterPurchaseResponseMessage,
      fontSize: '1em',
    },
    ExclamationIcon: {
      ...defaultTheme.OfferDetail.ExclamationIcon,
      width: 126,
      height: 'auto',
    },
  },
  LinkAccountForm: {
    ...defaultTheme.LinkAccountForm,
    ScreenBackground: {
      backgroundImage: `url(${assetSource(ASSET_KEY.LIGHT_DIVIDER)})`,
      backgroundSize: '80%',
      backgroundRepeat: 'no-repeat',
      backgroundPositionX: 'center',
      backgroundPositionY: 31,
    },
    HeaderTitle: {
      ...defaultTheme.LinkAccountForm.HeaderTitle,
      fontSize: '1.4rem',
      margin: 4,
    },
    NeedHelpText: {
      ...defaultTheme.LinkAccountForm.NeedHelpText,
      right: '1.5rem',
    },
  },
  VIPItem: {
    ...defaultTheme.VIPItem,
    LogoImage: {
      height: isTablet || isNotStandaloneMobilePWA ? '50%' : '75%',
      marginLeft: '0.5rem',
      position: 'relative',
      top: isTablet
        ? isStandalonePWA
          ? '1rem'
          : '0.5rem'
        : isMobile
          ? isNotStandaloneMobilePWA
            ? '1rem'
            : '0.2rem'
          : '-1rem',
    },
    VIPHourTile: {
      ...defaultTheme.VIPItem.VIPHourTile,
      marginTop: isTablet && windowWidth > 1150 ? (isStandalonePWA ? 0 : '-3%') : 0,
      marginLeft: windowWidth < 1300 ? '1.1rem' : isMobile ? 0 : '1.1rem',
    },
    ItemPlusOffer: {
      ...defaultTheme.VIPItem.ItemPlusOffer,
      marginTop:
        isStandalonePWA && !isTablet ? '-4vh' : isMobile && !isTablet ? (windowHeight > 343 ? '-1.7vh' : 0) : '-1rem',
    },
    LoyaltyPointsLogo: {
      ...defaultTheme.VIPItem.LoyaltyPointsLogo,
      ...(!isTablet &&
        isNotStandaloneMobilePWA && {
          height: '4rem',
          width: '4rem',
        }),
    },
  },
  VideoAds: {
    ...defaultTheme.VideoAds,
    CloseText: {
      ...defaultTheme.VideoAds.CloseText,
      fontSize: '16px',
    },
    KeepWatchingText: {
      ...defaultTheme.VideoAds.KeepWatchingText,
      fontSize: '16px',
    },
  },
  Settings: {
    ...defaultTheme.Settings,
    BackgroundLightDivider: {
      ...defaultTheme.Settings.BackgroundLightDivider,
      backgroundImage: `url(${assetSource(ASSET_KEY.LIGHT_DIVIDER)})`,
      backgroundSize: '80%',
      backgroundRepeat: 'no-repeat',
      backgroundPositionX: 'center',
      backgroundPositionY: 31,
    },
    HeaderImage: {
      ...defaultTheme.Settings.HeaderImage,
      height: '4.7rem',
    },
    BackArrowContainer: {
      ...defaultTheme.Settings.BackArrowContainer,
      top: '1.5rem',
    },
  },
  Wheel: {
    ...defaultTheme.Wheel,
    Amount: {
      ...defaultTheme.Wheel.Amount,
      color: theme.palette.common[1],
      textShadow: '-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000',
    },
  },
  ResetPassword: {
    ...defaultTheme.ResetPassword,
    FormInputsFields: {
      ...defaultTheme.ResetPassword.FormInputsFields,
      background: 'rgba(255, 255, 255, 0.05)',
      border: '1px solid rgba(255, 255, 255, 0.3)',
    },
    InputLabel: {
      ...defaultTheme.ResetPassword.InputLabel,
      color: theme.palette.common[1],
    },
  },
  SignUp: {
    ...defaultTheme.SignUp,
    FormInputTheme: {
      ...defaultTheme.SignUp.FormInputTheme,
      background: 'rgba(255, 255, 255, 0.05)',
      borderRadius: '0.6em',
      border: '1px solid rgba(255, 255, 255, 0.3)',
    },
    TermsLink: {
      ...defaultTheme.SignUp.TermsLink,
      color: theme.palette.primary[9],
    },
    NeedHelpLink: {
      ...defaultTheme.SignUp.NeedHelpLink,
      color: theme.palette.primary[9],
    },
  },
  TermsModalWrapper: {
    ...defaultTheme.TermsModalWrapper,
    NeedHelpLink: {
      ...defaultTheme.TermsModalWrapper.NeedHelpLink,
      color: theme.palette.primary[9],
    },
  },
  GameUnavailable: {
    ...defaultTheme.GameUnavailable,
    ButtonText: {
      ...defaultTheme.GameUnavailable.ButtonText,
      paddingTop: '0px',
    },
  },
  BottomBar: {
    ...defaultTheme.BottomBar,
    Divider: {
      ...defaultTheme.BottomBar.Divider,
      bottom: -10,
    },
    WatchAndEarnIcon: {
      ...defaultTheme.BottomBar.WatchAndEarnIcon,
      height: '4.8em',
    },
    SlotsIcon: {
      ...defaultTheme.BottomBar.SlotsIcon,
      height: '4.8em',
    },
    VIPIcon: {
      ...defaultTheme.BottomBar.VIPIcon,
      height: '5em',
      marginBottom: 10,
    },
    RewardCenterIcon: {
      ...defaultTheme.BottomBar.RewardCenterIcon,
      height: '7em',
      marginBottom: -4,
    },
    MailIcon: {
      ...defaultTheme.BottomBar.MailIcon,
      height: '4.8em',
    },
    TimerLabel: {
      ...defaultTheme.BottomBar.TimerLabel,
      position: 'relative',
      bottom: 0,
      fontSize: !isTablet && isMobile ? '1.3em' : '1em',
      paddingLeft: isMobile ? '20%' : '15%',
      marginBottom: isTablet ? '-10%' : isMobile ? '-6%' : '-12%',
    },
    TimerWrapper: {
      ...defaultTheme.BottomBar.TimerWrapper,
      height: '68%',
      bottom: isTablet ? '-0.1em' : 0,
    },
    TimerCollectBackground: {
      ...defaultTheme.BottomBar.TimerCollectBackground,
      bottom: isTablet ? '-0.5em' : '-0.2em',
    },
    TimerBackground: {
      ...defaultTheme.BottomBar.TimerBackground,
      height: '100%',
      objectFit: 'contain',
      bottom: '-0.5em',
    },
  },
  StoreDownload: {
    ...defaultTheme.StoreDownload,
    Container: {
      ...defaultTheme.StoreDownload.Container,
      backgroundColor: 'black',
    },
  },
  RewardCenter: {
    ...defaultTheme.RewardCenter,
    RewardCenterMainContentHolder: {
      ...defaultTheme.RewardCenter.RewardCenterMainContentHolder,
      paddingLeft: '5px',
      paddingRight: '5px',
    },
    ProfileFooterLink: {
      ...defaultTheme.RewardCenter.ProfileFooterLink,
      color: theme.palette.primary[9],
    },
  },
  CreditCardDetails: {
    ...defaultTheme.CreditCardDetails,
    CheckBoxBackgroundImage: {
      ...defaultTheme.CreditCardDetails.CheckBoxBackgroundImage,
      width: '40%',
    },
  },
  Start: {
    ...defaultTheme.Start,
    Logo: {
      ...defaultTheme.Start.Logo,
      marginTop: windowHeight > 700 ? '6%' : '0px',
    },
    MainContentContainer: {
      ...defaultTheme.Start.MainContentContainer,
      marginTop: windowHeight > 700 ? 30 : -20,
    },
    MiddleLayoutButtonContainer: {
      ...defaultTheme.Start.MiddleLayoutButtonContainer,
      marginTop: isNotStandaloneMobilePWA ? '10rem' : '4rem',
    },
    MiddleSectionWrapper: {
      ...defaultTheme.Start.MiddleSectionWrapper,
      paddingInlineStart: '0rem',
    },
    BottomBarFooterText: {
      ...defaultTheme.Start.BottomBarFooterText,
      marginTop: 10,
      textShadow: '2px 2px 2px #000000',
    },
  },
  ShopScreen: {
    ...defaultTheme.ShopScreen,
    ShopContainer: {
      ...defaultTheme.ShopScreen.ShopContainer,
      PackagesContainer: {
        ...defaultTheme.ShopScreen.ShopContainer.PackagesContainer,
        padding: '6% 5.5rem 0.4rem',
        CoinItem: {
          ...defaultTheme.ShopScreen.ShopContainer.PackagesContainer.CoinItem,
          left: '2px',
        },
        InnerItem: {
          ...defaultTheme.ShopScreen.ShopContainer.PackagesContainer.InnerItem,
          StackLoyaltyIcon: {
            ...defaultTheme.ShopScreen.ShopContainer.PackagesContainer.InnerItem.StackLoyaltyIcon,
            width: 35,
            position: 'relative',
            top: '0.2rem',
          },
        },
      },
    },
  },
  MissionsProgress: {
    ...defaultTheme.MissionsProgress,
    MissionIcon: {
      ...defaultTheme.MissionsProgress.MissionIcon,
      top: isMobile ? '-0.8rem' : -13,
    },
    TimeRemainingText: {
      ...defaultTheme.MissionsProgress.TimeRemainingText,
      right: isMobile ? 'calc(50% - 53px)' : windowWidth < 1400 ? 'calc(50% - 48px)' : 'calc(50% - 63px)',
    },
    FillProgress: {
      ...defaultTheme.MissionsProgress.FillProgress,
      marginTop: windowWidth < 1400 ? '-12px' : '-8px',
    },
    FillProgressWithoutCard: {
      ...defaultTheme.MissionsProgress.FillProgressWithoutCard,
      marginTop: '-8px',
    },
    FillEnd: {
      ...defaultTheme.MissionsProgress.FillEnd,
      marginTop: windowWidth < 1400 ? '-12px' : '-8px',
    },
    FillEndWithoutCard: {
      ...defaultTheme.MissionsProgress.FillEndWithoutCard,
      marginTop: '-8px',
    },
    ProgressAnimation: {
      ...defaultTheme.MissionsProgress.ProgressAnimation,
      bottom: windowWidth < 1400 ? '-165%' : '-85%',
    },
    ProgressAnimationWithoutCard: {
      ...defaultTheme.MissionsProgress.ProgressAnimationWithoutCard,
      bottom: '-85%',
    },
  },
  CurrencyDisplay: {
    ...defaultTheme.CurrencyDisplay,
    Icons: {
      ...defaultTheme.CurrencyDisplay.Icons,
      loyalty: {
        ...defaultTheme.CurrencyDisplay.Icons.loyalty,
        left: -25,
      },
    },
  },
  ForgotPassword: {
    ...defaultTheme.ForgotPassword,
    ModalContainer: {
      ...defaultTheme.ForgotPassword.ModalContainer,
      height: isMobile ? '45vh' : '35vh',
      minHeight: '300px',
    },
    ModalBody: {
      ...defaultTheme.ForgotPassword.ModalBody,
      marginBottom: '2%',
      justifyContent: 'center',
    },
  },
  TimeRemaining: {
    ...defaultTheme.TimeRemaining,
    VIPTimer: {
      ...defaultTheme.TimeRemaining.VIPTimer,
      top: pwaStyleAdapter(5, 'rem'),
    },
    TimerLabel: {
      ...defaultTheme.TimeRemaining.TimerLabel,
      color: theme.palette.common[1],
    },
    TimerLabelMobile: {
      ...defaultTheme.TimeRemaining.TimerLabelMobile,
      fontSize: pwaStyleAdapter(0.9, 'rem'),
    },
    MobileVipTimerContainer: {
      ...defaultTheme.TimeRemaining.MobileVipTimerContainer,
      width: '11rem',
    },
  },
  Login: {
    ...defaultTheme.Login,
    ModalContainer: {
      ...defaultTheme.Login.ModalContainer,
      height: isNotStandaloneMobilePWA ? '55vh' : '45vh',
    },
    ModalStyle: {
      ...defaultTheme.Login.ModalStyle,
      paddingBottom: isNotStandaloneMobilePWA ? (windowHeight < 320 ? '20vh' : '12vh') : 0,
    },
  },
  SideBar: {
    ...defaultTheme.SideBar,
    MenuItem: {
      ...defaultTheme.SideBar.MenuItem,
      padding: `0 0 0 ${isMobile && !isTablet ? 7.5 : windowWidth < 1400 ? 2 : 3.5}rem`,
    },
    MenuContainer: {
      ...defaultTheme.SideBar.MenuContainer,
      border: '5px solid #00bedd',
      background: 'linear-gradient(to bottom, #006677 0%, #007b92 25%, #1699b2 50%, #007b92 75%, #006677 100%)',
    },
  },
  BillingProfile: {
    ...defaultTheme.BillingProfile,
    TermsAndConditionsLinkText: {
      ...defaultTheme.BillingProfile.TermsAndConditionsLinkText,
      color: theme.palette.primary[9],
    },
  },
  MessageList: {
    ...defaultTheme.MessageList,
    ScrollBarCustomStyle: {
      ...defaultTheme.MessageList.ScrollBarCustomStyle,
      height: '100%',
    },
  },
  OfferDetailDescription: {
    ...defaultTheme.OfferDetailDescription,
    ButtonIcon: {
      ...defaultTheme.OfferDetailDescription.ButtonIcon,
      width: '3.5em',
      margin: '0 5px 5px 3px',
    },
    ButtonText: {
      ...defaultTheme.OfferDetailDescription.ButtonText,
      position: 'relative',
      left: '-0.5rem',
      fontSize: 32,
      paddingBottom: '3.2%',
    },
  },
  BrowserScreen: {
    ...defaultTheme.BrowserScreen,
    DescriptionWrap: {
      ...defaultTheme.BrowserScreen.DescriptionWrap,
      marginTop: 0,
    },
    ButtonContainer: {
      ...defaultTheme.BrowserScreen.ButtonContainer,
      marginTop: '5vh',
    },
  },
};

export default JamulTheme;

