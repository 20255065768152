import _slicedToArray from "/var/jenkins/workspace/Consolidated/Code/Dev/Web@2/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import { useState } from 'react';
import { generateInfoScreen } from '../utils/generateBattleRendering';
var useBattlesInfo = function useBattlesInfo(_ref) {
  var battlesAssets = _ref.battlesAssets;
  var _useState = useState(false),
    _useState2 = _slicedToArray(_useState, 2),
    openModal = _useState2[0],
    setOpenModal = _useState2[1];
  var _useState3 = useState(null),
    _useState4 = _slicedToArray(_useState3, 2),
    tutorialScreen = _useState4[0],
    setTutorialScreen = _useState4[1];
  var tutorialScreens = generateInfoScreen(battlesAssets);
  var findIndexScreen = function findIndexScreen() {
    return tutorialScreens.findIndex(function (x) {
      return x === tutorialScreen;
    });
  };
  var handleBackScreen = function handleBackScreen() {
    return setTutorialScreen(tutorialScreens[findIndexScreen() - 1]);
  };
  var handleFowardScreen = function handleFowardScreen() {
    return setTutorialScreen(tutorialScreens[findIndexScreen() + 1]);
  };
  var handleOpenModal = function handleOpenModal() {
    setTutorialScreen(tutorialScreens[0]);
    setOpenModal(true);
  };
  return {
    openModal: openModal,
    setOpenModal: setOpenModal,
    handleOpenModal: handleOpenModal,
    tutorialScreen: tutorialScreen,
    findIndexScreen: findIndexScreen,
    handleBackScreen: handleBackScreen,
    handleFowardScreen: handleFowardScreen
  };
};
export default useBattlesInfo;