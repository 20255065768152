import _defineProperty from "/var/jenkins/workspace/Consolidated/Code/Dev/Web@2/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { FTUE_EVENT_TYPES } from '../../../constants';
import { MISSIONS_EVENT_TYPES } from '../../../screens/Missions/constants';
import { ACTION_COMMANDS_EVENT_TYPE } from '../../../api/ActionCommandsInterface/constants';
import { EVENTS_EVENT_TYPES } from '../../../screens/Events/constants';
import { WELCOME_NEWS_EVENT_TYPES } from '../../../screens/News/constants';
/**
 * EVENT_TYPES should remain as abstract as possible to encourage cross-platform
 * implementation.
 *
 * Consider using VIEW (which makes sense on all platforms)
 * instead of PAGE_VIEW (which only really makes sense on web)
 */

var GTM_EVENT = {
  GTM_APP_INSTALLED: 'GTM_APP_INSTALLED',
  GTM_FEATURE_ENGAGEMENT: 'GTM_FEATURE_ENGAGEMENT'
};
export var EVENT_TYPES = _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({
  VIEW: 'VIEW',
  FRONTEND_ERROR: 'FRONTEND_ERROR',
  REGISTRATION_COMPLETE: 'REGISTRATION_COMPLETE',
  PURCHASE_COMPLETE: 'PURCHASE_COMPLETE',
  TUTORIAL_COMPLETE: 'TUTORIAL_COMPLETE',
  LOGIN_COMPLETE: 'LOGIN_COMPLETE',
  TUTORIAL_STARTED: 'TUTORIAL_STARTED',
  TUTORIAL_STEP_COMPLETE: 'TUTORIAL_STEP_COMPLETE',
  TUTORIAL_SKIPPED: 'TUTORIAL_SKIPPED',
  GAME_LOADED: 'GAME_LOADED',
  DAILY_WHEEL_CLAIMED: 'DAILY_WHEEL_CLAIMED',
  LEVEL_UP: 'LEVEL_UP',
  INBOX_MESSAGE_ACTION_CLICKED: 'INBOX_MESSAGE_ACTION_CLICKED',
  OFFER_REDEEMED: 'OFFER_REDEEMED',
  MEMBER_ACCOUNT_LINKED: 'MEMBER_ACCOUNT_LINKED',
  RATE_APP_POPUP_SHOWED: 'RATE_APP_POPUP_SHOWED',
  FAVOURITE_GAME_ADDED: 'FAVOURITE_GAME_ADDED',
  FAVOURITE_GAME_REMOVED: 'FAVOURITE_GAME_REMOVED',
  KYC_BASIC_CHECK_INIT: 'KYC_BASIC_CHECK_INIT',
  KYC_BASIC_CHECK_COMPLETED: 'KYC_BASIC_CHECK_COMPLETED',
  KYC_BASIC_CHECK_FAILED: 'KYC_BASIC_CHECK_FAILED',
  KYC_DOCUMENT_CHECK_INIT: 'KYC_DOCUMENT_CHECK_INIT',
  KYC_DOCUMENT_CHECK_COMPLETED: 'KYC_DOCUMENT_CHECK_COMPLETED',
  KYC_DOCUMENT_CHECK_FAILED: 'KYC_DOCUMENT_CHECK_FAILED',
  OOC_POP_UP_SHOWED: 'OOC_POP_UP_SHOWED',
  TRANSACTION_INITIATED: 'TRANSACTION_INITIATED',
  TRANSACTION_FAILED: 'TRANSACTION_FAILED',
  TRANSACTION_PENDING: 'TRANSACTION_PENDING',
  SETTING_AMBIENT_SOUND_TOGGLE: 'SETTING_AMBIENT_SOUND_TOGGLE',
  SETTING_SOUND_EFFECTS_TOGGLE: 'SETTING_SOUND_EFFECTS_TOGGLE',
  USER_CONTACTED_SUPPORT: 'USER_CONTACTED_SUPPORT',
  AD_FINISHED: 'AD_FINISHED',
  SETTINGS_PROMO_EMAIL_TOGGLE: 'SETTINGS_PROMO_EMAIL_TOGGLE',
  REWARD_CENTER_OPENED: 'REWARD_CENTER_OPENED',
  USER_ACCOUNT_LINKED: 'USER_ACCOUNT_LINKED',
  SUCCESFUL_LOGIN: 'SUCCESFUL_LOGIN',
  REQUEST_SENT: 'REQUEST_SENT',
  RESPONSE_RECEIVED: 'RESPONSE_RECEIVED',
  GAMES_OPENED_AMOUNT: 'GAMES_OPENED_AMOUNT',
  ACCOUNT_DELETION_REQUESTED: 'ACCOUNT_DELETION_REQUESTED',
  MISS_YOU_NOTIFICATION_INTERACTION: 'MISS_YOU_NOTIFICATION_INTERACTION',
  PROMOLINK_CLICKED: 'PROMOLINK_CLICKED',
  UI_EVENT: 'UI_EVENT'
}, MISSIONS_EVENT_TYPES), FTUE_EVENT_TYPES), ACTION_COMMANDS_EVENT_TYPE), EVENTS_EVENT_TYPES), WELCOME_NEWS_EVENT_TYPES), GTM_EVENT);