import { createSlice } from '@reduxjs/toolkit';
var sliceName = 'PROPERTIES';
var initialState = {
  data: {
    defaultSignupFormSettings: {},
    enterRequirements: [],
    propertySelector: [],
    userProperty: null,
    showLinkLoyaltyCardSetting: true,
    enableOrderCard: false,
    linkCards: null,
    dailyWheelCard: null,
    cardLinkPopupImage: null,
    playerPropertyLoyaltyCardType: null,
    isTermsUpdated: null,
    loyaltyCardComingSoon: false
  },
  errorMsg: ''
};
var propertiesSlice = createSlice({
  name: sliceName,
  initialState: initialState,
  reducers: {
    propertiesRequestDataSuccess: function propertiesRequestDataSuccess(state, action) {
      state.isFetchingData = false;
      state.data = action.payload;
    },
    setUpdatedTermsState: function setUpdatedTermsState(state, action) {
      state.data.isTermsUpdated = action.payload;
    }
  }
});
var actions = propertiesSlice.actions,
  reducer = propertiesSlice.reducer;
var propertiesRequestData = actions.propertiesRequestData,
  propertiesIsFetching = actions.propertiesIsFetching,
  propertiesRequestError = actions.propertiesRequestError,
  propertiesRequestDataSuccess = actions.propertiesRequestDataSuccess,
  setUpdatedTermsState = actions.setUpdatedTermsState;
export { propertiesRequestData, propertiesIsFetching, propertiesRequestError, propertiesRequestDataSuccess, setUpdatedTermsState };
export default reducer;