var ASSETS_KEYS = {
  /**
   * Missions asset keys
   */
  BACKGROUND: 'background/background.png',
  COMING_SOON: 'background/coming-soon.png',
  NO_MISSIONS: 'background/no-missions.png',
  NOTIFICATION: 'notification/notification.png',
  GRAND_PRIZE: 'cards/card-master-prize/master-prize-frame.png',
  GRAND_PRIZE_IMAGE: 'cards/card-master-prize/master-prize-image.png',
  CARD_BLUE: 'cards/card-background/card-blue.png',
  CARD_PURPLE: 'cards/card-background/card-purple.png',
  CARD_ORANGE: 'cards/card-textbox/textbox-orange.png',
  CARD_GREEN: 'cards/card-textbox/textbox-green.png',
  CARD_SHADOW: 'cards/card-textbox/textbox-shadow.png',
  COIN_IMAGE: 'cards/card-images/image-coins.png',
  COLLECT_BONUS_IMAGE: 'cards/card-images/image-collect-bonus.png',
  FREE_SPINS_IMAGE: 'cards/card-images/image-free-spins.png',
  GAME_ICON_IMAGE: 'cards/card-images/image-game.png',
  LEVEP_UP_IMAGE: 'cards/card-images/image-level-up.png',
  UNLOCK_IMAGE: 'cards/card-images/image-unlock.png',
  BET_TEXT: 'cards/card-titles/title-bet.png',
  COLLECT_BONUS_TEXT: 'cards/card-titles/title-collect-bonus.png',
  FREE_SPINS_TEXT: 'cards/card-titles/title-free-spins.png',
  LEVEP_UP_TEXT: 'cards/card-titles/title-level-up.png',
  MAX_BET_TEXT: 'cards/card-titles/title-max-bet.png',
  SPIN_TEXT: 'cards/card-titles/title-spin.png',
  UNLOCK_TEXT: 'cards/card-titles/title-unlock.png',
  WIN_TEXT: 'cards/card-titles/Win.png',
  BAR_BASE_BLUE: 'cards/card-progress-bar/bar-base-blue.png',
  BAR_BASE_PURPLE: 'cards/card-progress-bar/bar-base-purple.png',
  BAR_FILL_SIDE: 'cards/card-progress-bar/bar-fill-side.png',
  BAR_FILL: 'cards/card-progress-bar/bar-fill.png',
  BAR_SHADOW: 'cards/card-progress-bar/bar-shadow.png',
  BAR_COLLECT_BUTTON: 'cards/card-progress-bar/collect-button.png',
  BAR_LOCKED_ICON: 'cards/card-icon/padlock.png',
  BAR_PLAY_ICON: 'cards/card-icon/play-button.png',
  MISSION_TIMER_ICON: 'cards/card-icon/timer.png',
  TOP_BAR_ICON: 'top-bar/mission-icon.png',
  TOP_BAR_BASE: 'top-bar/top-bar-base.png',
  TOP_BAR_COLLECT: 'top-bar/top-bar-collect-button.png',
  TOP_BAR_FILL: 'top-bar/top-bar-fill.png',
  TOP_BAR_SIDE: 'top-bar/top-bar-side.png',
  TOP_BAR_TIMER: 'top-bar/top-bar-timer.png',
  TABS_BATTLES_1: 'tabs/battles/battles-1.png',
  TABS_BATTLES_2: 'tabs/battles/battles-2.png',
  TABS_BATTLES_3: 'tabs/battles/battles-3.png',
  TABS_EXPERT_1: 'tabs/expert/expert-1.png',
  TABS_EXPERT_2: 'tabs/expert/expert-2.png',
  TABS_EXPERT_3: 'tabs/expert/expert-3.png',
  TABS_EXPLORER_1: 'tabs/explorer/explorer-1.png',
  TABS_EXPLORER_2: 'tabs/explorer/explorer-2.png',
  TABS_EXPLORER_3: 'tabs/explorer/explorer-3.png',
  TUTORIAL_INFO_BUTTON: 'tutorial/info-button/info-button.png',
  TUTORIAL_INFO_SCREEN_STEP_1: 'tutorial/info-screen/info-screen-1.png',
  TUTORIAL_INFO_SCREEN_STEP_2: 'tutorial/info-screen/info-screen-2.png',
  TUTORIAL_INFO_SCREEN_STEP_3: 'tutorial/info-screen/info-screen-3.png',
  TUTORIAL_INFO_SCREEN_ARROW: 'tutorial/info-screen/info-screen-arrow.png',
  TUTORIAL_INFO_SCREEN_CLOSE_BTN: 'tutorial/info-screen/info-screen-close.png',
  TUTORIAL_TOOLTIP_STEP_1: 'tutorial/tooltips/tooltip-0.png',
  TUTORIAL_TOOLTIP_STEP_2: 'tutorial/tooltips/tooltip-1.png',
  TUTORIAL_TOOLTIP_STEP_3: 'tutorial/tooltips/tooltip-2.png',
  TUTORIAL_TOOLTIP_STEP_4: 'tutorial/tooltips/tooltip-3.png',
  TUTORIAL_TOOLTIP_STEP_5: 'tutorial/tooltips/tooltip-4.png',
  TUTORIAL_TOOLTIP_STEP_6: 'tutorial/tooltips/tooltip-5.png',
  TUTORIAL_TOOLTIP_STEP_7: 'tutorial/tooltips/tooltip-6.png',
  TUTORIAL_TOOLTIP_STEP_8: 'tutorial/tooltips/tooltip-7.png',
  TUTORIAL_TOOLTIP_STEP_9: 'tutorial/tooltips/tooltip-8.png',
  TUTORIAL_TOOLTIP_STEP_10: 'tutorial/tooltips/tooltip-9.png',
  ANIMATIONS_LIGHT_SWEEP_03: 'animations/progress-bar/light-sweep-00.png',
  ANIMATIONS_LIGHT_SWEEP_04: 'animations/progress-bar/light-sweep-01.png',
  ANIMATIONS_LIGHT_SWEEP_05: 'animations/progress-bar/light-sweep-02.png',
  ANIMATIONS_LIGHT_SWEEP_06: 'animations/progress-bar/light-sweep-03.png',
  ANIMATIONS_LIGHT_SWEEP_07: 'animations/progress-bar/light-sweep-04.png',
  ANIMATIONS_LIGHT_SWEEP_08: 'animations/progress-bar/light-sweep-05.png',
  ANIMATIONS_LIGHT_SWEEP_09: 'animations/progress-bar/light-sweep-06.png',
  ANIMATIONS_LIGHT_SWEEP_10: 'animations/progress-bar/light-sweep-07.png',
  ANIMATIONS_LIGHT_SWEEP_11: 'animations/progress-bar/light-sweep-08.png',
  ANIMATIONS_LIGHT_SWEEP_12: 'animations/progress-bar/light-sweep-09.png',
  ANIMATIONS_LIGHT_SWEEP_13: 'animations/progress-bar/light-sweep-10.png',
  ANIMATIONS_LIGHT_SWEEP_14: 'animations/progress-bar/light-sweep-11.png',
  ANIMATIONS_LIGHT_SWEEP_15: 'animations/progress-bar/light-sweep-12.png',
  ANIMATIONS_LIGHT_SWEEP_16: 'animations/progress-bar/light-sweep-13.png',
  ANIMATIONS_LIGHT_SWEEP_17: 'animations/progress-bar/light-sweep-14.png',
  ANIMATIONS_LIGHT_SWEEP_18: 'animations/progress-bar/light-sweep-15.png',
  ANIMATIONS_LIGHT_SWEEP_19: 'animations/progress-bar/light-sweep-16.png',
  ANIMATIONS_LIGHT_SWEEP_20: 'animations/progress-bar/light-sweep-17.png',
  ANIMATIONS_LIGHT_SWEEP_21: 'animations/progress-bar/light-sweep-18.png',
  ANIMATIONS_LIGHT_SWEEP_22: 'animations/progress-bar/light-sweep-19.png',
  ANIMATIONS_LIGHT_SWEEP_23: 'animations/progress-bar/light-sweep-20.png',
  ANIMATIONS_LIGHT_SWEEP_24: 'animations/progress-bar/light-sweep-21.png',
  ANIMATIONS_LIGHT_SWEEP_25: 'animations/progress-bar/light-sweep-22.png',
  ANIMATIONS_LIGHT_SWEEP_26: 'animations/progress-bar/light-sweep-23.png',
  ANIMATIONS_LIGHT_SWEEP_27: 'animations/progress-bar/light-sweep-24.png',
  ANIMATIONS_LIGHT_SWEEP_28: 'animations/progress-bar/light-sweep-25.png',
  ANIMATIONS_LIGHT_SWEEP_29: 'animations/progress-bar/light-sweep-26.png',
  TOOLTIP_BACKING: 'tooltip/tooltip-backing.png',
  CARD_BLUE_FRAME: 'cards/card-frame/card-blue-frame.png',
  CARD_PURPLE_FRAME: 'cards/card-frame/card-purple-frame.png',
  MODAL_BACKGROUND: 'modal-background.png',
  /**
   * Daily Spin asset keys
   */
  DAILY_SPIN_BACKGROUND: 'background/background.jpg',
  DAILY_SPIN_CALENDAR_ICON: 'bottom-bar/calendar.png',
  DAILY_SPIN_CARDS_ICON: 'bottom-bar/cards.png',
  DAILY_SPIN_BOTTOM_BOX: 'bottom-bar/bottom-inset.png',
  DAILY_SPIN_WHEEL_ICON: 'bottom-bar/wheel.png',
  DAILY_SPIN_TEXT_LOGIN: 'bottom-bar/text-login.png',
  DAILY_SPIN_TEXT_TOTAL: 'bottom-bar/text-total.png',
  DAILY_SPIN_BOTTOM_BACKGROUND: 'bottom-bar/bottom-background.png',
  DAILY_SPIN_WHEEL: 'wheel/wheel-big.png',
  DAILY_SPIN_WHEEL_TICKER: 'wheel/wheel-ticker.png',
  DAILY_SPIN_WHEEL_FRAME: 'wheel/wheel-frame.png',
  DAILY_SPIN_WHEEL_SPIN_BTN: 'wheel/wheel-spin-button.png',
  DAILY_SPIN_WHEEL_BTN_LOGO: 'wheel/wheel-logo-button.png',
  DAILY_SPIN_PRIZE_STACK: 'wheel/coin-stack.png',
  DAILY_SPIN_WHEEL_SKIP_BTN: 'wheel/skip-button.png',
  DAILY_SPIN_COLUMN_BACKGROUND: 'column/column-background.png',
  DAILY_SPIN_STREAK_ROW_DIVIDER: 'column/row-divider.png',
  DAILY_SPIN_STREAK_DAY_INDICATOR: 'column/row-indicator.png',
  DAILY_SPIN_COLUMN_LIGHT_1: 'column/light-1.png',
  DAILY_SPIN_COLUMN_LIGHT_2: 'column/light-2.png',
  DAILY_SPIN_COLUMN_LIGHT_3: 'column/light-3.png',
  DAILY_SPIN_COLUMN_LIGHT_4: 'column/light-4.png',
  DAILY_SPIN_COLUMN_LIGHT_5: 'column/light-5.png',
  DAILY_SPIN_COLUMN_LIGHT_6: 'column/light-6.png',
  DAILY_SPIN_COLUMN_LIGHT_7: 'column/light-7.png',
  DAILY_SPIN_COLUMN_LIGHT_8: 'column/light-8.png',
  DAILY_SPIN_COLUMN_LIGHT_9: 'column/light-9.png',
  DAILY_SPIN_COLUMN_LIGHT_OFF: 'column/light-off.png',
  DAILY_SPIN_COLUMN_RIBBON: 'column/ribbon.png',
  DAILY_SPIN_COLUMN_RIBBON_HERO: 'column/ribbon-hero.png',
  DAILY_SPIN_COLUMN_INFO_ICON: 'column/info-icon.png',
  DAILY_SPIN_COLUMN_INFO_POPUP: 'column/info-popup.png',
  DAILY_SPIN_MILESTONE_CIRCLE: 'column/prize-circle.png',
  DAILY_SPIN_INFO_POPUP: 'info/daily-spin-info.png',
  /**
   * Sweeptakes Spin asset keys
   */
  EVENTS_BACKGROUND: 'background/sweepstakes-background.png',
  EVENTS_CARD_BLUE: 'cards/cards-background/cards-blue.png',
  EVENTS_CARD_PURPLE: 'cards/cards-background/cards-purple.png',
  EVENTS_CARD_ORANGE: 'cards/cards-background/cards-orange.png',
  EVENTS_CARD_MULTIlPLE_GAMES: 'cards/cards-background/cards-multiple-games.png',
  EVENTS_CARD_MULTIlPLE_ACTIVITIES: 'cards/cards-background/cards-multiple-activities.png',
  EVENTS_CARD_ALL_GAMES: 'cards/cards-background/cards-all-games.png',
  EVENTS_CARD_BOTTOM_BLUE: 'cards/cards-bottom/cards-bottom-blue.png',
  EVENTS_CARD_BOTTOM_PURPLE: 'cards/cards-bottom/cards-bottom-purple.png',
  EVENTS_CARD_BOTTOM_ORANGE: 'cards/cards-bottom/cards-bottom-orange.png',
  EVENTS_CARD_BOTTOM_PINK: 'cards/cards-bottom/cards-bottom-pink.png',
  EVENTS_CARD_FRAME_GLOW: 'cards/cards-frame/cards-frame-glow.png',
  EVENTS_CARD_FRAME_GLOW_LIVE_OPS: 'cards/cards-frame/cards-frame-glow-orange.png',
  EVENTS_CARD_FRAME_SHADOW: 'cards/cards-frame/cards-frame-shadow.png',
  EVENTS_CARD_IMAGE_LIVE_OPS: 'cards/cards-image/cards-events-live-ops.png',
  EVENTS_MULTIPLE_ACTIVITIES_TITLE: 'cards/cards-title/cards-multiple-activities-title.png',
  EVENTS_MULTIPLE_GAMES_TITLE: 'cards/cards-title/cards-multiple-games-title.png',
  EVENTS_ALL_GAMES_TITLE: 'cards/cards-title/cards-all-games-title.png',
  EVENTS_PODIUM_ACTIVE: 'podium/podium-active.png',
  EVENTS_PODIUM_DISABLED: 'podium/podium-disabled.png',
  EVENTS_PODIUM_LIGHT: 'podium/podium-light.png',
  EVENTS_DEFAULT_RIBBON: 'ribbon/default-ribbon/default-ribbon.png',
  EVENTS_DEFAULT_RIBBON_SHADOW: 'ribbon/default-ribbon/default-ribbon-shadow.png',
  EVENTS_MAIN_RIBBON_TITLE: 'ribbon/main-ribbon/main-ribbon.png',
  EVENTS_MAIN_RIBBON_CROWN: 'ribbon/main-ribbon/main-ribbon-crown.png',
  EVENTS_MAIN_RIBBON_TROMBONE: 'ribbon/main-ribbon/main-ribbon-trombone.png',
  EVENTS_MAIN_RIBBON_TITLE_SHADOW: 'ribbon/main-ribbon/main-ribbon-shadow.png',
  EVENTS_MAIN_RIBBON_CROWN_SHADOW: 'ribbon/main-ribbon/main-ribbon-crown-shadow.png',
  EVENTS_MAIN_RIBBON_TROMBONE_SHADOW: 'ribbon/main-ribbon/main-ribbon-trombone-shadow.png',
  EVENTS_DETAIL_CLOSE_BUTTON: 'details/details-background/close-button.png',
  EVENTS_DETAIL_CARD_BLUE: 'details/details-background/details-blue.png',
  EVENTS_DETAIL_CARD_PURPLE: 'details/details-background/details-purple.png',
  EVENTS_DETAIL_CARD_ORANGE: 'details/details-background/details-orange.png',
  EVENTS_DETAIL_CARD_PINK: 'details/details-background/details-pink.png',
  EVENTS_DETAIL_CARD_GREY: 'details/details-background/details-grey.png',
  EVENTS_DETAIL_COUNTER_BLUE: 'details/details-counter/details-counter-container/details-counter-container-blue.png',
  EVENTS_DETAIL_COUNTER_PURPLE: 'details/details-counter/details-counter-container/details-counter-container-purple.png',
  EVENTS_DETAIL_COUNTER_ORANGE: 'details/details-counter/details-counter-container/details-counter-container-orange.png',
  EVENTS_DETAIL_COUNTER_PINK: 'details/details-counter/details-counter-container/details-counter-container-pink.png',
  EVENTS_DETAIL_COUNTER_GREY: 'details/details-counter/details-counter-container/details-counter-container-grey.png',
  EVENTS_DETAIL_BAR_BLUE: 'details/details-counter/details-counter-bar/details-counter-bar-blue.png',
  EVENTS_DETAIL_BAR_PURPLE: 'details/details-counter/details-counter-bar/details-counter-bar-purple.png',
  EVENTS_DETAIL_BAR_ORANGE: 'details/details-counter/details-counter-bar/details-counter-bar-orange.png',
  EVENTS_DETAIL_BAR_PINK: 'details/details-counter/details-counter-bar/details-counter-bar-pink.png',
  EVENTS_DETAIL_BAR_GREY: 'details/details-counter/details-counter-bar/details-counter-bar-grey.png',
  EVENTS_DETAIL_FILLABLE_BAR_BASE_ORANGE: 'details/details-counter/details-counter-filleable/details-counter-filleable-base-orange.png',
  EVENTS_DETAIL_FILLABLE_BAR_FILL_ORANGE: 'details/details-counter/details-counter-filleable/details-counter-filleable-fill-orange.png',
  EVENTS_DETAIL_FILLABLE_BAR_CAP_ORANGE: 'details/details-counter/details-counter-filleable/details-counter-filleable-cap-orange.png',
  EVENTS_DETAIL_FILLABLE_BAR_BASE_GREY: 'details/details-counter/details-counter-filleable/details-counter-filleable-base-grey.png',
  EVENTS_DETAIL_FILLABLE_BAR_FILL_GREY: 'details/details-counter/details-counter-filleable/details-counter-filleable-fill-grey.png',
  EVENTS_DETAIL_FILLABLE_BAR_CAP_GREY: 'details/details-counter/details-counter-filleable/details-counter-filleable-cap-grey.png',
  EVENTS_DETAIL_CARD_LOGO: 'details/details-logo/details-logo.png',
  EVENTS_TOOTLIP: 'tooltip/tooltip.png',
  EVENTS_NOTIFICATION_BADGE: 'icons/notification-badge.png',
  EVENTS_ICON_ACTIVE: 'icons/events-active.png',
  EVENTS_ERROR: 'icons/events-error.png',
  EVENTS_COMING_SOON: 'icons/events-coming-soon.png',
  EVENTS_MUST_UPDATE: 'icons/events-must-update.png',
  EVENTS_ICON_ACTIVE_MENU_VERTICAL: 'icons/events-active-menu-vertical.png',
  EVENTS_ERROR_MENU_VERTICAL: 'icons/events-error-menu-vertical.png',
  EVENTS_COMING_SOON_MENU_VERTICAL: 'icons/events-coming-soon-menu-vertical.png',
  EVENTS_MUST_UPDATE_MENU_VERTICAL: 'icons/events-must-update-menu-vertical.png',
  EVENTS_TUTORIAL_SLIDE_ONE_ICON: 'tutorial/info-targeted-events-icon.png',
  EVENTS_TUTORIAL_SLIDE_TWO_ICON: 'tutorial/info-sweepstakes-icons.png',
  EVENTS_TUTORIAL_SLIDE_THREE_ICON: 'tutorial/info-entries-icon.png',
  EVENTS_TUTORIAL_SLIDE_FOUR_ICON: 'tutorial/info-messages-icon.png',
  EVENTS_TUTORIAL_SLIDE_INDICATOR_SELECTED: 'tutorial/info-dot-selected.png',
  EVENTS_TUTORIAL_SLIDE_INDICATOR: 'tutorial/info-dot-unselected.png',
  EVENTS_TUTORIAL_INFO_MODAL_BG: 'tutorial/info-modal.png',
  EVENTS_TUTORIAL_INFO_ICON: 'tutorial/info-information-button.png',
  EVENTS_TUTORIAL_CLOSE_ICON: 'tutorial/info-close-button.png',
  /**
   * Reward Center asset keys
   */
  REWARD_CENTER_ONBOARDING: "onboarding-modal/onboarding.png",
  REWARD_CENTER_BACKGROUND: "main-screen/background/main-screen-background.jpg",
  REWARD_CENTER_FRAME_CARD: "main-screen/frame-card/main-screen-frame-card.png",
  REWARD_CENTER_FRAME_CARD_DISABLED: "main-screen/frame-card/main-screen-frame-card-disabled.png",
  REWARD_CENTER_FRAME_CARD_SHADOW: "main-screen/frame-card/main-screen-frame-card-shadow.png",
  REWARD_CENTER_FRAME_CARD_IS_NEW: "main-screen/frame-card/new-notification.png",
  REWARD_CENTER_OFFER_FRAME_CARD: "offer-details/offer-details-frame-card.png",
  REWARD_CENTER_PROFILE_ICON: "main-screen/profile/profile-icon.png",
  REWARD_CENTER_RIBBON_EXCLUSIVE: "main-screen/ribbons/exclusive-ribbon.png",
  REWARD_CENTER_RIBBON_FEATURED: "main-screen/ribbons/featured-ribbon.png",
  REWARD_CENTER_RIBBON_HOT: "main-screen/ribbons/hot-ribbon.png",
  REWARD_CENTER_RIBBON_LIMITED: "main-screen/ribbons/limited-ribbon.png",
  REWARD_CENTER_RIBBON_NEW: "main-screen/ribbons/new-ribbon.png",
  REWARD_CENTER_RIBBON_SOLD_OUT: "main-screen/ribbons/sold-out-ribbon.png",
  REWARD_CENTER_IMAGE_COINS: "coins-cards/coin-card-3.png",
  REWARD_CENTER_CLOSED_MENU_BACKGROUND: "menu/closed-menu/closed-menu-background.png",
  REWARD_CENTER_CLOSED_MENU_TITLE: "menu/closed-menu/closed-menu-title.png",
  REWARD_CENTER_CLOSED_MENU_CATEGORIES: "menu/closed-menu/closed-menu-categories.png",
  REWARD_CENTER_CLOSED_MENU_ARROW: "menu/closed-menu/closed-menu-arrow.png",
  REWARD_CENTER_OPENED_MENU_BACKGROUND: "menu/opened-menu/opened-menu-frame.png",
  REWARD_CENTER_OPENED_MENU_TITLE: "menu/opened-menu/opened-menu-title.png",
  REWARD_CENTER_OPENED_MENU_ARROW: "menu/opened-menu/opened-menu-arrow.png",
  REWARD_CENTER_OPENED_MENU_BUTTON_DEFAULT: "menu/opened-menu/opened-menu-default-button.png",
  REWARD_CENTER_OPENED_MENU_BUTTON_ACTIVE: "menu/opened-menu/opened-menu-active-button.png",
  REWARD_CENTER_OPENED_MENU_BUTTON_DISABLED: "menu/opened-menu/opened-menu-disabled-button.png",
  REWARD_CENTER_MENU_TABS_LOYALTY_POINTS: "menu/tabs/loyalty-points.png",
  REWARD_CENTER_MENU_TABS_LOYALTY_POINTS_SELECTED: "menu/tabs/loyalty-points-selected.png",
  REWARD_CENTER_MENU_TABS_MY_HISTORY: "menu/tabs/my-history.png",
  REWARD_CENTER_MENU_TABS_MY_HISTORY_SELECTED: "menu/tabs/my-history-selected.png",
  REWARD_CENTER_MENU_TABS_MY_PROFILE: "menu/tabs/my-profile.png",
  REWARD_CENTER_MENU_TABS_MY_PROFILE_SELECTED: "menu/tabs/my-profile-selected.png",
  REWARD_CENTER_MENU_UNDERLINE: "menu/tabs/underline.png",
  REWARD_CENTER_MENU_HISTORY_BANNER: "menu/details/history-banner.png",
  REWARD_CENTER_PURCHASE_SUCCESS: "offer-details/purchase-success.png",
  REWARD_CENTER_OFFER_DETAILS_ARROW: "offer-details/offer-details-arrow.png",
  REWARD_CENTER_PROFILE_FRAME_CARD: "profile/profile-frame-card.png",
  REWARD_CENTER_PROFILE_LINK_BUTTON: "profile/link-button.png",
  REWARD_CENTER_COINS_ICON: "offer-details/coins-icon.png",
  REWARD_CENTER_COINS_ICON_DISABLED: "offer-details/coins-icon-disabled.png",
  REWARD_CENTER_LOYALTY_ICON: "offer-details/loyalty-icon.png",
  REWARD_CENTER_LOYALTY_ICON_DISABLED: "offer-details/loyalty-icon-disabled.png",
  REWARD_CENTER_OFFER_DETAILS_PURPLE_BAR: "offer-details/purple-bar.png",
  REWARD_CENTER_ARROW_BACK_BUTTON: "main-screen/background/arrow-back-button.png",
  /**
   * Social asset keys
   */
  SOCIAL_FRIENDS_MORE_COINS_TITLE: 'title-more-friends.png',
  SOCIAL_FRIENDS_CHARACTERS_IMAGE: 'characters.png',
  SOCIAL_FRIENDS_UNDER_MAINTENANCE: 'under-maintenance.png',
  SOCIAL_FRIENDS_COMING_SOON: 'coming-soon.png',
  SOCIAL_LIMIT_REACHED_IMAGE: 'limit-reached.png',
  SOCIAL_MESSAGES_DIVIDER: 'message-divider.png',
  /** Localization */
  LOYALTY_ICON: 'loyalty-icon.png',
  JACKPOT_DISPLAYED_SCREEN: 'progressive-jackpots/jackpot-displayed-screen.png',
  MESSAGE_INBOX_LOYALTY_PROMO: 'message-inbox-loyalty-promo.png',
  SHOP_PURPLE_TRIANGLE_DIVIDER: 'shop-purple-triangle-divider.png',
  VIP_ACCESS_STORE_TILE_3_PROMO: 'vip-access-store-tile-3-promo.png',
  VIP_ACCESS_STORE_PROMO_FREE: 'vip-access-store-promo-free.png',
  PURPLE_TIMER: 'purple-timer.png',
  COIN_ICON: 'coin-assets/coin-icon.png',
  COIN_GLOW: 'coin-assets/coin-glow.png',
  SLOT_COINS: 'slot-coins.png',
  ROUNDEDRECT_DISABLED_WIDE: 'roundedrect-disabled-wide.png',
  SKIP_BUTTON: 'ftue/skip-button.png',
  SEND_MESSAGE_ICON: 'settings/send-message-icon.png',
  SETTINGS_DIVIDER: 'settings/settings-divider.png',
  FORWARD_ARROW: 'settings/forward-arrow.png',
  EXCLAMATION_ICON: 'settings/exclamation-icon.png',
  SELECT_ARROW: 'settings/select-arrow.png',
  ICON_SHOW: 'icon-show.png',
  STARS_GRAPHIC: 'stars-graphic.png',
  FORM_CONTINUE_BUTTON: 'form-continue-button.png',
  TOP_BAR_BACKING: 'top-bar-backing.png',
  CONTINUE_WITH_MAIL: 'link-guest-account/continue-with-mail.png',
  APPLE: 'link-guest-account/apple.png',
  GOOGLE: 'link-guest-account/google.png',
  FACEBOOK: 'link-guest-account/facebook.png',
  TIMER_COLLECT: 'timed-bonus-assets/timer-collect.png',
  TIMED_BONUS_OPEN: 'timed-bonus-assets/timed-bonus-open.png',
  TIMER_WAITING: 'timed-bonus-assets/timer-waiting.png',
  TIMED_BONUS_CLOSED: 'timed-bonus-assets/timed-bonus-closed.png',
  WATCH: 'bottom-bar-assets/watch.png',
  WATCH_MENU_VERTICAL: 'bottom-bar-assets/watch-menu-vertical.png',
  VIP_ENTRY_LOCKED: 'bottom-bar-assets/vip-entry-locked.png',
  MAIL: 'bottom-bar-assets/mail.png',
  MAIL_MENU_VERTICAL: 'bottom-bar-assets/mail-menu-vertical.png',
  BACKING: 'bottom-bar-assets/backing.png',
  REWARDS: 'bottom-bar-assets/rewards.png',
  VIP_ROOM: 'bottom-bar-assets/vip-room.png',
  BOTTOM_BAR_DIVIDER: 'bottom-bar-assets/divider.png',
  SLOTS: 'bottom-bar-assets/slots.png',
  GTP_MISSIONS_ICON_TEXT: 'bottom-bar-assets/gtp-missions-icon-text.png',
  MISSIONS: 'bottom-bar-assets/missions.png',
  MISSIONS_MENU_VERTICAL: 'bottom-bar-assets/missions-menu-vertical.png',
  WEB_BACKING: 'bottom-bar-assets/web-backing.png',
  BOTTOM_BAR_BACKING_SLIM: 'bottom-bar-assets/backing-slim.png',
  BOTTOM_BAR_PLUS_BUTTON: 'bottom-bar-assets/plus-button.png',
  BOTTOM_BAR_ARROW_BUTTON: 'bottom-bar-assets/arrow-button.png',
  BOTTOM_BAR_MENU_SLIM: 'bottom-bar-assets/menu-slim.png',
  ROUNDED_RECT_DISABLED: 'rounded-rect-disabled.png',
  CARD_BRONZE: 'card-bronze.png',
  CLICK_HERE_BUTTON: 'click-here-button.png',
  SHOP_PURPLE_CAP: 'shop-purple-cap.png',
  SESSION_EXPIRED_IMAGE: 'session-expired-image/session-expired-image.png',
  COINS_MAIL_ICON: 'coins-mail-icon.png',
  ROUNDED_RECT_DANGER: 'rounded-rect-danger.png',
  CREDIT_CARD_LOGOS: 'purchase-flow-assets/credit-card-logos.png',
  PILE_OF_COINS: 'purchase-flow-assets/pile-of-coins.png',
  WARNING_SYMBOL: 'purchase-flow-assets/warning-symbol.png',
  VISA: 'purchase-flow-assets/visa.png',
  MESSAGE_CHAT_BOX: 'purchase-flow-assets/message-chat-box.png',
  MASTERCARD: 'purchase-flow-assets/mastercard.png',
  AMEX: 'purchase-flow-assets/amex.png',
  PURCHASE_LIGHT_DIVIDER: 'purchase-flow-assets/light-divider.png',
  ROUNDEDRECT_PRIMARY_WIDE: 'roundedrect-primary-wide.png',
  VIP_ACCESS_STORE_TILE_2_PROMO: 'vip-access-store-tile-2-promo.png',
  SHOP_BEST_DEAL: 'shop-best-deal.png',
  NEWS_ITEM_FRAME_9_SLICE: 'news-item-frame-9-slice.png',
  COIN_GRAPHIC_SALE: 'promo-links-assets/coin-graphic-sale.png',
  BUY_NOW_BUTTON: 'promo-links-assets/buy-now-button.png',
  DIVIDER: 'divider.png',
  TOP_BAR_LOYALTY_ICON: 'top-bar-assets/loyalty-icon.png',
  IAP_SPLIT_GREEN: 'top-bar-assets/iap-split-green.png',
  PURPLE_EXP_BAR: 'top-bar-assets/purple-exp-bar.png',
  IAP_SPLIT_SALE: 'top-bar-assets/iap-split-sale.png',
  MENU_HAMBURGER: 'top-bar-assets/menu-hamburger.png',
  HOME_BUTTON: 'top-bar-assets/home-button.png',
  EXP_SHIELD: 'top-bar-assets/exp-shield.png',
  TOP_BAR_ASSETS_BACKING: 'top-bar-assets/backing.png',
  IAP_SPLIT_PINK: 'top-bar-assets/iap-split-pink.png',
  TOP_BAR_BAR_INSET: 'top-bar-assets/bar-inset.png',
  IAP_BACKING: 'top-bar-assets/iap-backing.png',
  TOP_BAR_COIN_ICON: 'top-bar-assets/coin-icon.png',
  IAP_WHOLE_SALE: 'top-bar-assets/iap-whole-sale.png',
  IAP_WHOLE: 'top-bar-assets/iap-whole.png',
  VIP_TICKET: 'top-bar-assets/vip-ticket.png',
  CLOCK_ICON: 'clock-icon.png',
  SHOP_GOLD_TRIANGLE_DIVIDER: 'shop-gold-triangle-divider.png',
  ICON_CASINODOLLARS: 'icon-casinodollars.png',
  SHOP_POPULAR: 'shop-popular.png',
  ERROR_BACKGROUND: 'error-screen/background.jpg',
  MESSAGE: 'error-screen/message.png',
  RATE_NOW_BUTTON: 'rate-now-button.png',
  RIGHT_ARROW: 'right-arrow.png',
  GOOGLE_BUTTON: 'start/buttons/google-button.png',
  MEMBER_BUTTON: 'start/buttons/member-button.png',
  LOGIN_GUEST_BUTTON: 'start/buttons/login-guest-button.png',
  GUEST_BUTTON: 'start/buttons/guest-button.png',
  APPLE_BUTTON: 'start/buttons/apple-button.png',
  FACEBOOK_BUTTON: 'start/buttons/facebook-button.png',
  SPLASH_BG: 'start/splash-bg.jpg',
  START_SCREEN_LOGO: 'start/start-screen-logo.png',
  SPLASH_BAR_BACKING: 'start/splash-bar-backing/splash-bar-backing.png',
  TRUCK8: 'animation/truck8.png',
  TRUCK10: 'animation/truck10.png',
  TRUCK11: 'animation/truck11.png',
  TRUCK9: 'animation/truck9.png',
  TRUCK13: 'animation/truck13.png',
  TRUCK12: 'animation/truck12.png',
  TRUCK16: 'animation/truck16.png',
  TRUCK17: 'animation/truck17.png',
  TRUCK15: 'animation/truck15.png',
  TRUCK14: 'animation/truck14.png',
  TRUCK1: 'animation/truck1.png',
  TRUCK18: 'animation/truck18.png',
  TRUCK2: 'animation/truck2.png',
  TRUCK3: 'animation/truck3.png',
  TRUCK7: 'animation/truck7.png',
  TRUCK6: 'animation/truck6.png',
  TRUCK4: 'animation/truck4.png',
  TRUCK5: 'animation/truck5.png',
  ROUNDED_RECT_QUATERNARY: 'rounded-rect-quaternary.png',
  DOT_UNSELECTED: 'slider/dot-unselected.png',
  DOT_SELECTED: 'slider/dot-selected.png',
  MAX_BET: 'level-up/max-bet.png',
  LEVEL_UP_LIGHT_DIVIDER: 'level-up/light-divider.png',
  INDEX: 'index.js',
  CARD_GOLD: 'card-gold.png',
  MODAL_BACKING_9_SLICE: 'modal-backing-9-slice.png',
  MODAL_BACKING_9_SLICE_WEB: 'modal-backing-9-slice-web.png',
  BACK_ARROW: 'back-arrow.png',
  LOYALTY_ICON_WEB: 'loyalty-icon-web.png',
  MOBILE_BACKGROUND_INPUT_FORMS: 'mobile-background-input-forms.png',
  TIMER_BACKING: 'watch-and-earn-assets/timer-backing.png',
  WATCH_EARN_POPUP_ICON: 'watch-and-earn-assets/watch&earn-popup-icon.png',
  COINS_TEX: 'watch-and-earn-assets/coins-tex.png',
  HEART_ON: 'favourites/heart-on.png',
  GAMES_IMAGE: 'favourites/games-image.png',
  HEART_OFF: 'favourites/heart-off.png',
  CARD_PLATINUM: 'card-platinum.png',
  SECTION: 'section.png',
  ROUNDED_RECT_PRIMARY: 'rounded-rect-primary.png',
  SHOP_VIP_DIVIDER_GLOWS: 'shop-vip-divider-glows.png',
  SHOP_GOLD_BACKING: 'shop-gold-backing.png',
  ACCESS_OPTIONS: 'vip-assets/access-options.png',
  PURCHASE_SUCCEEDED: 'vip-assets/purchase-succeeded.png',
  VIP_BACKGROUND: 'vip-assets/vip-background.jpg',
  GLOWING_CONTAINER: 'vip-assets/glowing-container.png',
  NAVIGATION_DARKER: 'vip-assets/navigation-darker.png',
  CARET_DOWN: 'caret-down.png',
  CHECKMARK: 'checkmark.png',
  GENERAL_BACKGROUND: 'background.png',
  PROMO_IMAGE_COINS: 'promo-image-coins.png',
  POPUP_FRAMING: 'popup-framing.png',
  POPUP_FRAMING_MOBILE: 'popup-framing-mobile.png',
  FLY_OUT_STAR: 'fly-out-star.png',
  SMART_BANNER_LOGO: 'smart-banner-logo.png',
  GAMES_LOCK: 'lobby-assets/games-lock.png',
  LOBBY_COMING_SOON: 'lobby-assets/coming-soon.png',
  LOBBY_BACKGROUND: 'lobby-assets/background.jpg',
  MESSAGE_BACKGROUND: 'social/message-background.png',
  UNDER_MAINTENANCE: 'social/under-maintenance.png',
  SOCIAL_COMING_SOON: 'social/coming-soon.png',
  VIP_ACCESS_STORE_TILE_1_PROMO: 'vip-access-store-tile-1-promo.png',
  DIALOG: 'dialog.png',
  VIP_PASS: 'vip-pass.png',
  WELCOME_BONUS: 'welcome-bonus-assets/welcome-bonus.png',
  WELCOME_BONUS_WEB: 'welcome-bonus-assets/welcome-bonus-web.png',
  BADGE_I_OS: 'tracking-url/badge-i-os.png',
  BADGE_WEB: 'tracking-url/badge-web.png',
  BADGE_WEB_APP: 'tracking-url/badge-web-app.png',
  TRACKING_URL_LOGO: 'tracking-url/tracking-url-logo.png',
  GREY_BUTTON_LANDING: 'tracking-url/grey-button-landing.png',
  GREEN_BUTTON_LANDING: 'tracking-url/green-button-landing.png',
  BADGE_ANDROID: 'tracking-url/badge-android.png',
  MAIL_MESSAGE_DEFAULT: 'mail-message-default.png',
  LOGO: 'logo.png',
  TIMER_DISPLAYED_SCREEN: 'sneak-peek/timer-displayed-screen.png',
  SNEAK_PEEK_ONBOARDING: 'sneak-peek/sneak-peek-onboarding.png',
  SNEAK_PEEK_BACKGROUND: 'sneak-peek/sneak-peek-background.png',
  ICON_SNEAK_PEEK: 'sneak-peek/icon-sneak-peek.png',
  PROMOTIONAL_GRAPHIC: 'promotional-graphics/promotional-graphic.png',
  FULL_MODAL_HEADER: 'full-modal-header.png',
  FULL_MODAL_HEADER_MOBILE: 'full-modal-header-mobile.png',
  COINS_TAB_ACTIVE: 'coin-store-assets/coins-tab-active.png',
  UPSELL_TAB_BEST_DEAL: 'coin-store-assets/upsell-tab-best-deal.png',
  ITEM_ROW_PURPLE: 'coin-store-assets/item-row-purple.png',
  COIN_GLOWING_CONTAINER: 'coin-store-assets/glowing-container.png',
  COIN_GLOWING_CONTAINER_MOBILE: 'coin-store-assets/glowing-container-mobile.png',
  UPSELL_TAB_POPULAR: 'coin-store-assets/upsell-tab-popular.png',
  UPSELL_TAB_AMAZING: 'coin-store-assets/upsell-tab-amazing.png',
  VIP_TAB_ACTIVE: 'coin-store-assets/vip-tab-active.png',
  ITEM_ROW_GOLD: 'coin-store-assets/item-row-gold.png',
  LOYALTY_ICON_BONUS: 'coin-store-assets/loyalty-icon-bonus.png',
  DEFAULT_BACKGROUND: 'coin-store-assets/default-background.jpg',
  VIP_HOUR_TILE_3: 'coin-store-assets/vip-tiles/vip-hour-tile-3.png',
  VIP_HOUR_TILE_2: 'coin-store-assets/vip-tiles/vip-hour-tile-2.png',
  VIP_HOUR_TILE_1: 'coin-store-assets/vip-tiles/vip-hour-tile-1.png',
  VIP_HOUR_TILE_1STAR: 'coin-store-assets/vip-tiles/vip-hour-tile-1star.png',
  VIP_HOUR_TILE_3STARS: 'coin-store-assets/vip-tiles/vip-hour-tile-3stars.png',
  VIP_HOUR_TILE_2STARS: 'coin-store-assets/vip-tiles/vip-hour-tile-2stars.png',
  VIP_HOUR_TILE_FREE: 'coin-store-assets/vip-tiles/vip-hour-tile-free.png',
  TOP_BAR_IAP_BACKING: 'top-bar-iap-backing.png',
  SHOP_VIP_DIVIDER: 'shop-vip-divider.png',
  ICON_HIDE: 'icon-hide.png',
  CLOSE_BUTTON: 'close-button.png',
  ROUNDED_RECT_SECONDARY: 'rounded-rect-secondary.png',
  ORANGE_TIMER: 'orange-timer.png',
  VISA_MC: 'visa-mc.png',
  SHOP_AMAZING: 'shop-amazing.png',
  ROUNDED_RECT_BLUE: 'rounded-rect-blue.png',
  COIN: 'coin-icon.png',
  TOAST_BACKGROUND: 'toast-assets/background.png',
  TILE_BACKER_9SLICE: 'tile-backer-9slice.png',
  DOWNLOAD_ICON: 'embedded-games-assets/download-icon.png',
  ACCEPT_BUTTON: 'notifications-permissions-assets/accept-button.png',
  INFO_POPUP: 'favorites-info-popup/info-popup.png',
  BACK_BUTTON: 'back-button.png',
  TAB_FRONT: 'tabs-assets/tab-front.png',
  TAB_BACK: 'tabs-assets/tab-back.png',
  COINS_DEFAULT: 'coins-default.png',
  CHECKBOX: 'checkbox.png',
  MODAL_BACKER: 'modal-backer.png',
  DAILY_BONUS_PRIZES_BOTTOM_BAR: 'daily-spin/daily-bonus-prizes-bottom-bar.png',
  TSC_CARDS: 'tsc-cards.png',
  CARD_DIAMOND: 'card-diamond.png',
  OUT_OF_COINS_STATIC_IMAGE: 'out-of-coins-assets/out-of-coins-static-image.png',
  MARK: 'mark.png',
  PRIVACY_POLICY: 'terms-and-conditions-buttons/privacy-policy.png',
  TERMS_CONDITIONS: 'terms-and-conditions-buttons/terms-conditions.png',
  MAIL_ICON_OPEN: 'mail-icon-open.png',
  LINK_NOW_BUTTON: 'link-account-info/link-now-button.png',
  PROMO_IMAGE_LOYALTY: 'promo-image-loyalty.png',
  RED_TIMER: 'red-timer.png',
  CARD_XCARD: 'card-xcard.png',
  LOYALTY_MAIL_ICON: 'loyalty-mail-icon.png',
  CLOCK_ICON_GLOWING: 'clock-icon-glowing.png',
  SHOP_GOLD_CAP: 'shop-gold-cap.png',
  PINK_BUTTON: 'pink-button.png',
  ROUNDEDRECT_TERTIARY_WIDE: 'roundedrect-tertiary-wide.png',
  ORANGE_BUTTON: 'orange-button.png',
  QUEST20_LOCK: 'quest20-lock.png',
  ICON_QUESTION: 'icon-question.png',
  CARD_PREMIER: 'card-premier.png',
  LTO_PROMO_IMAGE: 'lto-promo-image/lto-promo-image.png',
  CHALLENGE_BAR_FILL_END: 'bars-assets/challenge-bar-fill-end.png',
  BAR_INSET_END: 'bars-assets/bar-inset-end.png',
  CHALLENGE_BAR_FILL: 'bars-assets/challenge-bar-fill.png',
  EXP_BAR_FILL_END: 'bars-assets/exp-bar-fill-end.png',
  BAR_INSET: 'bars-assets/bar-inset.png',
  EXP_BAR_FILL: 'bars-assets/exp-bar-fill.png',
  SIDEBAR_ARROW_BUTTON: 'side-bar-assets/sidebar-arrow-button.png',
  SIDEBAR_BUTTON: 'side-bar-assets/sidebar-button.png',
  SIDEBAR_BUTTON_HIGHLIGHTED: 'side-bar-assets/sidebar-button-highlighted.png',
  CHECKBOX_DESELECTED: 'checkbox-deselected.png',
  ROUNDED_RECT_TERTIARY: 'rounded-rect-tertiary.png',
  CARD_SILVER: 'card-silver.png',
  EMPTY_INFO: 'empty-favorites-message/empty-info.png',
  CARD_ELITE: 'card-elite.png',
  LINK_BUTTON: 'rewards-center-assets/link-button.png',
  REWARD_TSC_CARDS: 'rewards-center-assets/tsc-cards.png',
  VIP_MESSAGE_ICON: 'vip-message-assets/vip-message-icon.png',
  VIP_MESSAGE_BACKGROUND: 'vip-message-assets/vip-message-background.png',
  LIGHT_DIVIDER: 'light-divider.png',
  POINTS_CARDS: 'points-cards.png',
  SPRITESHEET: 'spritesheet.png',
  SCROLL_INDICATOR: 'scroll-indicator.png',
  LOADING_FILL_SIDE: 'asset-preloader/loading-fill-side.png',
  LOADING_BAR_INSET: 'asset-preloader/loading-bar-inset.png',
  LOADING_LOGO: 'asset-preloader/loading-logo.png',
  LOADING_FILL_CENTER: 'asset-preloader/loading-fill-center.png',
  LOADING_BAR_BACKING: 'asset-preloader/loading-bar-backing.png',
  LOADING_BAR_FILL: 'asset-preloader/loading-bar-fill.png',
  LOADING_SCREEN: 'asset-preloader/loading-screen.jpg',
  LOADING_LOGO_LEFT: 'asset-preloader/loading-logo-left.png',
  CLOSE_BUTTON_DANGER: 'close-button-danger.png',
  MESSAGE_COINS_BACKGROUND: 'social/messages/coins-message-background.png',
  MESSAGE_COINS_ICON: 'social/messages/coins-message-icon.png',
  MESSAGE_LOYALTY_BACKGROUND: 'social/messages/loyalty-message-background.png',
  MESSAGE_LOYALTY_ICON: 'social/messages/loyalty-message-icon.png',
  MESSAGE_LOYALTY_SUBTITLE_ICON: 'social/messages/loyalty-message-subtitle-icon.png',
  MESSAGE_SNEAKPEEK_BACKGROUND: 'social/messages/sneakpeek-message-background.png',
  MESSAGE_SNEAKPEEK_ICON: 'social/messages/sneakpeek-message-icon.png',
  MESSAGE_VIP_BACKGROUND: 'social/messages/vip-message-background.png',
  MESSAGE_VIP_ICON: 'social/messages/vip-message-icon.png',
  MESSAGE_DEFAULT_BACKGROUND: 'social/messages/default-message-background.png',
  INPUT_EMAIL_ICON_REGULAR: 'input-icons/email-icon-regular.png',
  INPUT_EMAIL_ICON_ERROR: 'input-icons/email-icon-error.png',
  INPUT_PASSWORD_ICON_REGULAR: 'input-icons/password-icon-regular.png',
  INPUT_PASSWORD_ICON_ERROR: 'input-icons/password-icon-error.png',
  SESSION_EXPIRED_CLOCK_ICON: 'session-expired-image/clock-icon.png',
  EVENTS_ICON_ACTIVE_BLOCK: 'bottom-bar-assets/events-icon-active-block.png',
  EVENTS_ICON_ACTIVE_CHECK: 'bottom-bar-assets/events-icon-active-check.png',
  EVENTS_ICON_ACTIVE_PEN: 'bottom-bar-assets/events-icon-active-pen.png',
  EVENTS_ICON_ACTIVE_TEXT: 'bottom-bar-assets/events-icon-active-text.png',
  MISSIONS_ICON_ARROW: 'bottom-bar-assets/missions-icon-arrow.png',
  MISSIONS_ICON_CIRCLE: 'bottom-bar-assets/missions-icon-circle.png',
  REWARD_CENTER_ICON_GLOW: 'bottom-bar-assets/reward-center-icon-glow.png',
  COMING_SOON_TEXT: 'coming-soon/coming-soon-text.png',
  LOBBY_BANNERS_ARROW_BUTTON: 'lobby-banners/arrow-button.png',
  LOBBY_BANNERS_SLIDER_MENU: 'lobby-banners/slider-menu.png',
  LOBBY_BANNERS_YOU_MIGHT_LIKE: 'lobby-banners/you-might-like-banner.png',
  LOBBY_BANNERS_NOW_TRENDING: 'lobby-banners/now-trending-banner.png',
  ROTATE_SCREEN_BACKGROUND: 'rotate-screen/portrait-background.jpg',
  ROTATE_SCREEN_ROTATE_ICON: 'rotate-screen/rotate-icon.png',
  ROTATE_SCREEN_UNLOCK_ICON: 'rotate-screen/unlock-icon.png',
  SOUND_ON: 'watch-and-earn-assets/sound-on.png',
  SOUND_OFF: 'watch-and-earn-assets/sound-off.png',
  PLAY_ON_ICON: 'watch-and-earn-assets/play-button.png',
  BROWSER_MOBILE_SCREEN_BACKGROUND: 'rotate-screen/landscape-background.jpg',
  BROWSER_MOBILE_SCREEN_SHARED_ICON: 'rotate-screen/shared-icon.png',
  BROWSER_MOBILE_SCREEN_ADD_ICON: 'rotate-screen/add-icon.png',
  /**
   * Sports access restriction flow
   */
  DIVIDER_ACCESS_RESTRICTED: 'sports-room-access/divider-access-restricted.png',
  CARDS_ACCESS_RESTRICTED: 'sports-room-access/cards-access-restricted.png',
  EMAIL_CONFIRMATION_BAKCGROUND: 'double-opt-in/background.png',
  EMAIL_CONFIRMATION_POPUP: 'double-opt-in/pop-up.png'
};
module.exports = {
  ASSETS_KEYS: ASSETS_KEYS
};