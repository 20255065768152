import React from 'react';
import ENVIRONMENT_VARIABLES from 'shared/utils/environmentVariables';
import {
  ContentContainer,
  ClientLogo,
  PageDescriptionContainer,
  MainImage,
  PageDescription,
  LinkIconHolder,
  InnerDiv,
  Badge,
  PageBottomText,
  MainContainer,
} from '../../styledComponents';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';
import CustomScrollBar from '../../../../components/CustomScrollBar';

function RegularTemplate({ aff_id, landingPageState, redirectToSplash }) {
  const { mainImage, trackingPageDescription, trackingPageBottomTxt } = landingPageState;

  return (
    <CustomScrollBar>
      <MainContainer>
        <ContentContainer>
          <ClientLogo src={assetSource(ASSET_KEY.TRACKING_URL_LOGO)} alt="" />
          <PageDescriptionContainer>
            <PageDescription>{trackingPageDescription}</PageDescription>
          </PageDescriptionContainer>
          <MainImage src={mainImage} alt="" onError="this.onerror=null" />
          <LinkIconHolder>
            {ENVIRONMENT_VARIABLES.APPLE_STORE_URL && (
              <InnerDiv>
                <a href={ENVIRONMENT_VARIABLES.APPLE_STORE_URL}>
                  <Badge src={assetSource(ASSET_KEY.BADGE_I_OS)} alt="" />
                </a>
              </InnerDiv>
            )}
            <InnerDiv>
              <a href={ENVIRONMENT_VARIABLES.ANDROID_STORE_URL}>
                <Badge src={assetSource(ASSET_KEY.BADGE_ANDROID)} alt="" />
              </a>
            </InnerDiv>
            <InnerDiv>
              <div href="#" onClick={() => redirectToSplash(aff_id.current)}>
                <Badge src={assetSource(ASSET_KEY.BADGE_WEB)} alt="" />
              </div>
            </InnerDiv>
          </LinkIconHolder>
          <PageBottomText>{trackingPageBottomTxt}</PageBottomText>
        </ContentContainer>
      </MainContainer>
    </CustomScrollBar>
  );
}

export default RegularTemplate;

